import TagManager from 'react-gtm-module';
import axios from "axios";
import ReactGA from 'react-ga';

// const GTM_ID = 'GTM-MZL68S2';



class GTM {
    constructor() {
        // detect ad blockers, was causing trouble otherwise
        this.isAccessible = window.dataLayer !== undefined;
    }

    // Initialize gtm container
    init(gtm_id) {
        const tagManagerArgs = {
            gtmId: gtm_id
        };

        TagManager.initialize(tagManagerArgs);
    }

    getClientId() {
        const ga = ReactGA.initialize('UA-106150927-1');
        // client ID was sent to backend at the registration finish
        // to be able to track loan final states - rejected or sent to bank
        if (!this.isAccessible)
            return null;

        if (ga.getAll === undefined)
            return null;

        const tracker = ga.getAll()[0];
        return tracker.get('clientId');
    }

    // dataLayer.push method with custom name for multiple dataLayers
    sendDataLayer(dataLayerName, args) {
        const tagManagerArgs = {
            dataLayer: args,
            // dataLayerName: dataLayerName
        };

        TagManager.dataLayer(tagManagerArgs);
    }

    // sending pageviews before gtm
    pageViews() {
        const pageViewsArgs = {
            dataLayer: {
                originalLocation: document.location.protocol + '//' +
                                  document.location.hostname +
                                  document.location.pathname +
                                  document.location.search
            },
            dataLayerName: 'Page Views: ' + document.location.pathname
        }

       TagManager.dataLayer(pageViewsArgs);
    }

    // send to gtm success loan request
    sendSuccessLoanRequest (clientID, eventValue) {
        axios.post(
            'https://www.google-analytics.com/collect?v=1&t=event&tid=UA-106150927-1&cid=' +
            clientID + '&ni=1&ec=%C5%BD%C3%A1dost%20o%20P%C5%AFj%C4%8Dku&ea=V%C3%BDsledek&el=Schv%C3%A1leno&ev=' +
            eventValue
        ).then(response => {
            console.log(response);
        }).catch(error => {
           console.log(error);
        });
    }

    // send to gtm failed loan request
    sendFailedLoanRequest (clientID, eventValue) {
        axios.post(
            'https://www.google-analytics.com/collect?v=1&t=event&tid=UA-106150927-1&cid=' +
            clientID + '&ni=1&ec=%C5%BD%C3%A1dost%20o%20P%C5%AFj%C4%8Dku&ea=V%C3%BDsledek&el=Zam%C3%ADtnuto&ev=' +
            eventValue
        ).then(response => {
            console.log(response)
        }).catch(error => {
            console.log(error);
        });
    }

}

export default GTM;