import React from 'react';

interface SpendingReportInputProps {
    title: any,
    register: any,
    name: string,
    onChange: (question, value) => void
}

const SpendingReportInput = (props: SpendingReportInputProps) => {
    return (
        <li className="calcSummary-listItem d-flex justify-content-between Question-listItem SpendingReport-listItem">
            <h2 className={"text-left"}>{props.title}</h2>
            <div className="currencyWrap">
                <input
                    {...props.register(props.name)}
                    type="text"
                    name={props.name}
                    onChange={(e) => props.onChange(props.name, e.target.value)}
                />
                <span className="currencyPlaceholder">Kč</span>
            </div>
        </li>
    );
};

export default SpendingReportInput;