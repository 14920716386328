import React, {useEffect} from 'react';
import {Col, Row} from "react-bootstrap";
import Wave from "assets/UserAssets/wawe.svg";
import Dashed from "assets/UserAssets/dashed.svg";
import Winner from "assets/UserAssets/winner.svg";
import IconRow from "pages/home/components/IconRow";
import Button from "components/Button";
import {useDispatch} from "useDispatch";
import AppControllerAction from "store/appControler/AppControllerAction";
import {AppStatus} from "types/enums/AppStatus";
import {AppProgressStatus} from "types/enums/AppProgressStatus";
import {RouteComponentProps, withRouter} from 'react-router';
import {AppCurrentStep} from "types/enums/AppCurrentStep";
import mobileDashed from "assets/UserAssets/mobileDashed.svg";
import LinkTo from "helpers/LinkTo";
import {openModal} from "store/action";
import ModalPriceList from "components/ModalPriceList";
import {useTypedSelector} from "helpers/reducers";
import Cookies from "components/Cookies/Cookies";
import {Cookies_status} from "types/enums/Cookies_status";
import Loader from "components/Loader";
import LoanAction from "store/loan/LoanAction";


interface Params{}

interface HomeProps extends RouteComponentProps<Params>{}

const Home = (props: HomeProps) => {
    const dispatch = useDispatch();
    const loanController = useTypedSelector(state => state.loanController);
    const openErrorModal = () => dispatch(openModal(<ModalPriceList/>, {}));
    const cookies = useTypedSelector(state => state.appController.cookies);

    // useEffect(() => {
    //     if (cookies?.status !== Cookies_status.NULL) {
    //         sendCookies();
    //     }
    // }, [cookies]);

    useEffect(() => {
        loanController.priceList.length === 0 && dispatch(LoanAction.fetchPriceList("fix"));
    }, []);

    const onStartBuildLoan = () => {
        if (loanController.priceList.length === 0) {
            openErrorModal();
        } else {
            dispatch(AppControllerAction.setAppStatus(AppStatus.InProgress));
            dispatch(AppControllerAction.setAppProgressStatus(AppProgressStatus.Request));
            dispatch(AppControllerAction.setCurrentStep(AppCurrentStep.Calculation));
            props.history.push(LinkTo.calculation());
        }
    }

    const sendCookies = (cookies) => {
        dispatch(AppControllerAction.sendCookies(cookies))
    }

    const agreeCookie = () => {
        const cookiesObject = {
            ...cookies,
            analytics_cookies: true,
            advert_cookies: true,
            status: Cookies_status.AGREED
        }
        dispatch(AppControllerAction.setCookies(cookiesObject))

        sendCookies(cookiesObject);
    }

    const disagreeCookie = () => {
        const cookiesObject = {
            ...cookies,
            status: Cookies_status.DISAGREED
        }

        dispatch(AppControllerAction.setCookies(cookiesObject));
        sendCookies(cookiesObject);
    }

    if (!cookies) {
        return <Loader/>
    }

    return (
        <div className={'intro d-flex flex-column justify-content-around h-100 pb-3 pb-md-0'}>
            <div className={"pb-4 pb-sm-1"}>
                <div className="winner-wrap">
                    <img src={Winner} alt=""/>
                </div>
                <Row className={"flex-column flex-lg-row justify-content-xl-start justify-content-lg-around align-items-between pl-lg-4"}>
                    <Col xs={12} lg="3" xl='4' className={'d-flex justify-content-center align-items-center order-2 order-lg-0 wawe mobile-alignment'}>
                        <img src={Wave} alt="Wawe Vister"/>
                    </Col>
                    <Col md='12' lg="9" xl="6"
                         className="intro-textBox align-self-center text-center order-0">
                        <h1 className="intro-heading mb-md-4 mb-lg-5"><b>VistaCredit</b> je český poskytovatel <br />
                            mikro-úvěru pro podnikatele.</h1>
                        <div className="what-we-do d-none d-lg-flex">
                            <div className="circle"><h3>Vista <span className="gradient-text">Energie</span></h3></div>
                            <div className="circle"><h3>Vista <span className="gradient-text">Nájemné</span></h3></div>
                            <div className="circle"><h3>Vista <span className="gradient-text">Autoservis</span></h3></div>
                            <div className="circle"><h3>Vista <span className="gradient-text">Fakturace</span></h3></div>
                            <div className="circle"><h3>Vista <span className="gradient-text">Cokoliv</span></h3></div>
                            <img src={Dashed} alt=""/>
                        </div>
                    </Col>
                    <Col xs={12} className={'d-flex justify-content-center d-lg-none order-1'}>
                        <img src={mobileDashed} className="what-we-do-mobile" alt=""/>
                    </Col>
                    <Row className={'justify-content-center w-100 order-3 order-lg-0 m-0 m-lg-auto'}>
                        <Col xs={12} lg={6} xl={4} className={'d-flex justify-content-center justify-content-lg-end'}>
                            <Button class={'btn-purple'}
                                    dataGTMid="chci-uver"
                                    disabled={cookies?.status === Cookies_status.NULL}
                                    onClick={onStartBuildLoan}>
                                <span>Chci úvěr od Visty</span>
                            </Button>
                        </Col>
                    </Row>
                </Row>
            </div>
            {cookies.status === Cookies_status.NULL && <Cookies agreeCookies={agreeCookie} disAgreeCookies={disagreeCookie}/>}
            <IconRow />
            <div style={{textAlign: "center", fontSize: '11px', color:  '#5a7984'}}>
                <p style={{marginBottom: '4px'}}>Reprezentativní příklad našeho úvěru :</p>
                <p><b>Půjčte si od nás 4 000 Kč</b> s <b>dobou splatnosti 35 dnů a</b> s <b>poplatkem za poskytnutí
                    úvěru ve výši 1 240 Kč</b>,  splatíte jednorázově po 35 dnech částku 5 240 Kč.
                    <b> Vista Vám neúčtuje žádné další poplatky</b> za zpracování žádosti, předčasné splacení, apd.
                    Výsledná RPSN i tak bude 1571 %. Vzhledem k tomu, že vzorec RPSN počítá vždy s minimální
                    roční dobou splatnosti je v tomto případě jeho výše za 35 dnů adekvátní době poskytnutí.
                </p>
            </div>
        </div>
    );
};

export default withRouter(Home);