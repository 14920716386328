import React from 'react';
import clsx from "clsx";
import InputElement from "components/InputElement";

interface InputProps {
    register: any,
    error?: any,
    type?: string,
    name: string,
    label: any
}

const Input = (props: InputProps) => {
    return (
        <div className="inputWrapper d-flex">
            <label className="inputLabel">{props.label}</label>
            <InputElement register={props.register} type={props.type} name={props.name} label={props.label} error={props.error}/>
        </div>
    );
};

export default Input;