import axios, {AxiosInstance} from "axios";
import {ROOT_STORAGE_DOMAIN} from "helpers/conifg";

export function CreateAxiosInstance(): AxiosInstance {

    const ajax = axios.create({
        baseURL: ROOT_STORAGE_DOMAIN
    });

    return ajax;
}

const defaultInstance = CreateAxiosInstance();
export default defaultInstance;

