import React from 'react';
import Info from "assets/UserAssets/info.svg";
import Listening from "assets/UserAssets/listening.svg";
import ViewContainer from "components/ViewContainer";
import ImageCol from "components/ImageCol";
import ContentCol from "components/ContentCol";
import {useTypedSelector} from "helpers/reducers";
import {useDispatch} from "useDispatch";
import SessionControllerAction from "store/sessionController/SessionControllerAction";
import Question from "pages/verification/components/Question";
import {SessionControllerState} from "store/sessionController/SessionControllerReducer";

const VerificationQuestions = props => {
    const sessionController: SessionControllerState = useTypedSelector(state => state.sessionController);
    const dispatch = useDispatch();

    const onQuestionAnswerHandler = (e, question, questionAnswer) => {
        e.preventDefault();
        dispatch(SessionControllerAction.verifyQuestion(sessionController.user.birth_number, question, questionAnswer));
        dispatch(SessionControllerAction.setUserQuestion({name: question, answer: questionAnswer}));
    };

    return (
        <ViewContainer>
            <ImageCol lg={3} image={Listening}/>
            <ContentCol lg={7}>
                <form>
                    <ul className="calcSummary-list Question-list">
                        <Question onClick={onQuestionAnswerHandler}
                                  question={(<>Mám <b>exekuci?</b></>)}
                                  type={'verification'}
                                  questionName={'has_execution'}
                                  successMessage={"To je více než dobrá zpráva."}
                                  failMessage={"To není příliš dobrá zpráva."}/>
                        <Question onClick={onQuestionAnswerHandler}
                                  question={(<><b>Trvalý pobyt</b> mám hlášený na městském úřadě</>)}
                                  questionName={'has_office_address'}
                                  type={'verification'}
                                  successMessage={"To je více než dobrá zpráva."}
                                  failMessage={"To není moc dobré, že máte trvalé bydliště hlášené na obecním nebo městském úřadě."}/>
                        <Question onClick={onQuestionAnswerHandler}
                                  question={(<>Budu žádat o <b>osobní bankrot</b> (insolvenci)</>)}
                                  questionName={'has_insolvency'}
                                  type={'verification'}
                                  successMessage={"To je více než dobrá zpráva."}
                                  failMessage={"Hmmm... tak to nás moc netěší."}/>
                    </ul>
                </form>
                <div className="loanSummary-info d-flex mb-0 w-100">
                    <img src={Info} className="notification-icon" alt=""/>
                    <p className="notification-text mb-0 mb-mf-5">
                        Prohlašuji, že mnou zvolené odpovědi jsou pravdivé!
                    </p>
                </div>
            </ContentCol>
        </ViewContainer>
    );
};

export default VerificationQuestions;