import React from 'react';
import ImageCol from "components/ImageCol";
import ThumbDown from "assets/UserAssets/thumbDown.svg";
import ContentCol from "components/ContentCol";
import ViewContainer from "components/ViewContainer";

interface Props {}

const Denied = (props: Props) => {
    return (
        <ViewContainer>
            <ImageCol lg={3} image={ThumbDown}/>
            <ContentCol lg={6} class={'failedBox'}>
                <div className="failedMessage">
                    <p>
                        Vážený kliente,
                        <br /><br />
                        <b>je nám to líto</b>, ale nesplňujete podmínky pro poskytnutí úvěru náší společností.
                        <br /><br />

                        Mějte se fajn.
                    </p>
                </div>
            </ContentCol>
        </ViewContainer>
    );
};

export default Denied;