import React, {useEffect, useState} from 'react';
import MoreDays from "assets/UserAssets/moreDays.svg";
import ThumbDown from "assets/UserAssets/thumbDown.svg";
import {useTypedSelector} from "helpers/reducers";
import ViewContainer from "components/ViewContainer";
import ContentCol from "components/ContentCol";
import CalculationSlider from "pages/calculation/components/CalculationSlider";
import {PriceListItem} from "types/entities";
import {calc_mpsn, calc_rpsn, getPaymentDate} from "shared/utility";
import Notification from "components/Notification";
import notification from "assets/UserAssets/notification.svg";
import clsx from "clsx";
import {useDispatch} from "useDispatch";
import LoanAction from "store/loan/LoanAction";
import {LoanReducerState} from "store/loan/LoanReducer";
import Loader from "components/Loader";

const Calculation = props => {
    const loanController: LoanReducerState = useTypedSelector(state => state.loanController);
    const dispatch = useDispatch();
    const [rangePrice, setRangePrice] = useState<number | number[]>(loanController.loan.base_price);
    const [rangeDays, setRangeDays] = useState<number | number[]>(loanController.loan.base_days_amount);
    const [loanStep, setLoanStep] = useState(1000);
    const [loanPrices, setLoanPrices] = useState({
        amount: null,
        loan_price: null,
        deferred_price: null,
        total_price: null,
    });

    useEffect(() => {
        const priceItem: PriceListItem = loanController?.priceList?.find(price => price.amount === rangePrice);
        if (priceItem?.amount > 8000) {
            setLoanPrices({
                ...loanPrices,
                loan_price: null,
                total_price: null,
                deferred_price: null,
            })
        } else {
            const loanPrice =
                priceItem?.loan_prices?.find(price => {
                    if(rangeDays <= 35) {
                        return price?.days === rangeDays
                    } else {
                        return price?.days === 35
                    }
                });
            const deferredPrice = priceItem?.deferral_prices?.find(price => price?.days === (+rangeDays - 35));
            const totalPrice = deferredPrice !== undefined ?
                loanPrice?.amount + rangePrice + deferredPrice?.amount
                : loanPrice?.amount + rangePrice;

            setLoanPrices({
                ...loanPrices,
                amount: priceItem?.amount,
                loan_price:  deferredPrice !== undefined ? loanPrice?.amount + deferredPrice?.amount : loanPrice?.amount,
                deferred_price: deferredPrice?.amount || null,
                total_price: totalPrice,
            })
        }
    }, [rangePrice, rangeDays]);

    useEffect(() => {
        dispatch(LoanAction.setLoanSelection({
            selected_price: rangePrice,
            selected_days: rangeDays,
            loan_price: loanPrices?.loan_price,
            deferral_fee: loanPrices?.deferred_price,
            total_loan_price: loanPrices?.total_price,
        }));
    }, [loanPrices]);

    const priceMarks = [{value: 5000},{value: 7500}, {value: 10000}, {value: 12500}, {value: 15000}, {value: 17500}, {value: 20000}, {value: 23000}]
    const daysMarks = [{value: 19.6},{value: 25.2},{value: 30.8},{value: 36.4},{value: 42},{value: 47.6},{value: 53.2},{value: 58.8},{value: 64.4}]

    return (
        <ViewContainer class={'inputRange-root loanWrap justify-content-between'}>
            <ContentCol lg={7} class={'d-flex flex-column justify-content-center pl-4'} >
                <div className={"choosePrice mb-4"}>
                    <div className="chooseValue-headingBox d-flex justify-content-between mb-5">
                        <h2>Jakou <b>částku požadujete?</b></h2>
                        <h2><b>{rangePrice},-</b></h2>
                    </div>
                    <CalculationSlider
                        defaultValue={rangePrice}
                        marks={priceMarks}
                        max={25000}
                        min={3000}
                        step={loanStep}
                        setSliderValue={setRangePrice}/>
                        <Notification imageUrl={notification} show={rangePrice > 8000}>
                            Pro tuto výši úvěru s klientem sjednáváme individuální podmínky
                        </Notification>
                </div>
                <div className={clsx("chooseDays", rangePrice > 8000 ? "pushDown" : "pushUp")}>
                    <div className="chooseValue-headingBox d-flex justify-content-between mb-5">
                        <h2>Za jakou dobu <b>úvěr splatíte?</b></h2>
                        <h2 className={'text-right'} style={{lineHeight: '22px'}}><span style={{fontSize: '16px'}}>splatím do </span><b>{rangeDays} dní</b> <br/>
                            <span style={{fontSize: '14px'}}>případně si prodloužím splatnost</span>
                        </h2>
                    </div>
                    <CalculationSlider
                        defaultValue={35}
                        marks={daysMarks}
                        max={70}
                        min={14}
                        step={7}
                        type={'fix'}
                        days={true}
                        setSliderValue={setRangeDays}/>
                    <img src={MoreDays} alt="" className="moreDays-img"/>
                </div>
            </ContentCol>

            <ContentCol lg="5" class={"mb-4 mb-md-5 mb-lg-0"}>
                <img src={ThumbDown} className={"loanOptions-thumbDown DesktopOnly "} alt="ThumbDown"/>
                <div className="calcSummary">
                    <h3 className="calcSummary-blue">Zvolili jste variantu:</h3>
                    <ul className="calcSummary-list">
                        <li className="calcSummary-listItem d-flex justify-content-between">
                            <span className="text-normal">Výše úvěru:</span>
                            <span className={"text-bold "}>{rangePrice},-</span>
                        </li>
                        <li className="calcSummary-listItem d-flex justify-content-between">
                            <span className="text-normal">Doba splatnosti:</span>
                            <span className="text-bold">{rangeDays} dní</span>
                        </li>
                        <li className="calcSummary-listItem d-flex justify-content-between">
                            <span className="text-normal">Poplatek za poskytnutí úvěru:</span>
                            <span className="text-bold">{
                                rangePrice > 8000
                                    ? ("bude sjednán individuálně")
                                    : loanPrices.loan_price + ',-'

                            }</span>
                        </li>
                        <li className="calcSummary-listItem">
                            <div className="d-flex justify-content-between">
                                <span className="text-normal">Celkem zaplatíte:</span>
                                <span className="text-bold">{
                                    rangePrice > 8000
                                        ? ("bude sjednán individuálně")
                                        : loanPrices.total_price + ',-'

                                }</span>
                            </div>
                            <div>
                                <span className="text-small">za {rangeDays} dní (do {getPaymentDate(null, rangeDays)})</span>
                            </div>
                        </li>
                        <li className="calcSummary-listItem">
                            <div className="d-flex justify-content-between">
                                <span className="text-small">rpsn: (roční úroková sazba)</span>
                                <span className="text-small">{
                                    rangePrice > 8000
                                    ? "bude sjednána individuálně"
                                    : calc_rpsn(rangePrice,
                                        loanController.loan.loan_selection.deferral_fee + loanController.loan.loan_selection.loan_price,
                                        rangeDays) + '%'
                                }</span>
                            </div>
                            <div className="d-flex justify-content-between">
                                <span className="text-small">mpsn: (měsíční úroková sazba)</span>
                                <span className="text-small">{
                                    rangePrice > 8000
                                        ? "bude sjednána individuálně"
                                        : calc_mpsn(rangePrice,
                                            loanController.loan.loan_selection.deferral_fee + loanController.loan.loan_selection.loan_price,
                                            rangeDays) + '%'
                                }</span>
                            </div>
                        </li>
                    </ul>
                </div>
            </ContentCol>
        </ViewContainer>

    );
};

export default Calculation;