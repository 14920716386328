import React, {useEffect} from 'react';
import ReactGA from 'react-ga';
import GTM from "GTM-tracker";
import {useDispatch} from "useDispatch";
import SessionControllerAction from "store/sessionController/SessionControllerAction";
import {HOST, HOSTNAME} from "index";
import {useTypedSelector} from "helpers/reducers";
import {AppControllerState} from "store/appControler/AppControllerReducer";
import {getNumberOfCurrentStep} from "helpers/utils";
import {LoanReducerState} from "store/loan/LoanReducer";
import {useLocation} from "react-router-dom";
import AppControllerAction from "store/appControler/AppControllerAction";


const Analytics = props => {
    const dispatch = useDispatch();
    const appController: AppControllerState = useTypedSelector(state => state.appController);
    const loanController: LoanReducerState = useTypedSelector(state => state.loanController);
    const isProduction = localStorage.getItem("isProduction");
    const gtm = new GTM();
    const location = useLocation();

    window.dataLayer = window.dataLayer || [];

    useEffect(() => {
        if (window.location.host === HOST || window.location.hostname === HOSTNAME) {
            ReactGA.initialize('UA-106150927-1');
            // console.log('production');
            window.dataLayer.push({
                originalLocation: document.location.protocol + '//' +
                    document.location.hostname +
                    document.location.pathname +
                    document.location.search
            });
            dispatch(AppControllerAction.setIsProduction(true));
        } else {
            dispatch(AppControllerAction.setIsProduction(false));
        }

    }, []);


    useEffect(() => {
        const currentStep = getNumberOfCurrentStep(appController.current_step);
        if (currentStep === 8) {
            window.dataLayer.push({
                'formName': "ZadostOUver",
                'formPart': 9,
                'formValue': loanController.loan.loan_selection.selected_price, // požadovaná částka
                // 'loanId': , // @todo ask @Lukas
                'event': 'formSent'
            });
        } else if (currentStep < 8) {
            window.dataLayer.push({
                'formName': "ZadostOUver",
                'formPart': currentStep,
                'event': 'formSent'
            });
        }
    }, [location.pathname]);

    if (appController.is_production) {
        ReactGA.ga((tracker) => {
            console.log('lol')
            dispatch(SessionControllerAction.setGaId(tracker.get('clientId')));
        });
        gtm.init('GTM-MZL68S2');
    }

    return null;
};

export default Analytics;