import React from 'react';
import clsx from "clsx";

interface ButtonProps {
    type?: 'submit' | 'reset' | 'button',
    class?: string,
    spinner?: boolean,
    onClick?: any,
    dataGTMid?: string | null,
    children: React.ReactNode | React.ReactNode[],
    disabled?: boolean
    style?: any
}

const Button = (props: ButtonProps) => {
    const type = props.type !== undefined ? props.type : "button";

    return (
        <button
            type={type}
            style={props.style ?? {}}
            className={clsx("btn", props.class)}
            disabled={props.disabled}
            onClick={props.onClick}
            data-gtm-element-id={props.dataGTMid}>
            {props.children}
        </button>
    );
};

export default Button;