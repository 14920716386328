import React from 'react';
import Button from "components/Button";
import {useDispatch} from "useDispatch";
import {closeModal} from "store/action";
import {Redirect} from "react-router-dom";
import LinkTo from "helpers/LinkTo";
import {RouteComponentProps, withRouter} from "react-router";

interface Params {}

interface Props extends RouteComponentProps<Params> {}

const VerificationByeByeModal = (props: Props) => {
    const dispatch = useDispatch();

    const byeByeHandler = () => {
        dispatch(closeModal());
        props.history.push(LinkTo.home());
    }

    return (
        <div className="VerificationByeByeModal">
            <h3 className="Modal-heading">Víte, že Vaše osvědčení (živnostenský list) <b>není k dnešnímu dni
                platné, a nebo je zrušené? </b> Bohužel, ale za těchto podmínek se nemůžete stát naším klientem. </h3>
            <p className="Modal-text">Pokud potřebujete bližší informace, kontaktujte naši zákaznickou podporu:</p>
            <a href="tel:+420587407707" className="Modal-phone d-block"><b>+420 587 407 707</b></a>
            <p className="Modal-text mt-3">nebo e-mail:</p>
            <a href="mailto:admin@vistacredit.cz" className="Modal-email d-block">admin@vistacredit.cz</a>
            <Button class={"btn btn-purple Modal-btn mt-5 mx-auto"} onClick={byeByeHandler}>Nashledanou</Button>
        </div>
    );
};

export default withRouter(VerificationByeByeModal);