import React from 'react';
import Info from "assets/UserAssets/info.svg";
import clsx from "clsx";

interface Props {
    text: any,
}

const Information = (props: Props) => {
    return (
        <div className={"loanSummary-info d-flex mb-0 mb-md-5 w-100"}>
            <img src={Info} className="notification-icon" alt="Information Icon"/>
            <p className="notification-text mb-0 mb-md-5">
                {props.text}
            </p>
        </div>
    );
};

export default Information;