import React, {useEffect, useState} from 'react';
import Cards from "assets/UserAssets/cards.svg";
import moreDaysToDay from "assets/UserAssets/moreDaysToday.svg";
import HandsTime from "assets/UserAssets/handsTime.svg";
import Info from "assets/UserAssets/info.svg";
import ViewContainer from "components/ViewContainer";
import ImageCol from "components/ImageCol";
import ContentCol from "components/ContentCol";
import {useDispatch} from "useDispatch";
import CalculationAddDays from "pages/calculation/components/CalculationAddDays";
import {openModal} from "store/action";
import {useTypedSelector} from "helpers/reducers";
import Loader from "components/Loader";
import {LoanReducerState} from "store/loan/LoanReducer";
import {calc_rpsn, getDeferralFee, getPaymentDate} from "shared/utility";
import {PriceListItem} from "types/entities";
import clsx from "clsx";

const CalculationSummary = props => {
    const dispatch = useDispatch();
    const loanController: LoanReducerState = useTypedSelector(state => state.loanController);
    const [oldDaysAmount, setOldDaysAmount] = useState(loanController.loan.loan_selection.selected_days || 0);
    const openAddMoreDaysModal = () => dispatch(openModal(<CalculationAddDays setNewDaysAmount={setOldDaysAmount}/>, {}))
    const [deferredPrice, setDeferredPrice] = useState({
        amount: null,
        days: null
    });

    useEffect(() => {
        const priceItem: PriceListItem = loanController?.priceList?.find(price => price.amount === loanController.loan.loan_selection.selected_price);
            const tmp = priceItem?.deferral_prices?.find(price => price.days === (70 - loanController?.loan.loan_selection.selected_days));
            setDeferredPrice(tmp);
            setOldDaysAmount(loanController.loan.loan_selection.selected_days);
    }, [loanController.loan.loan_selection]);

    if (!loanController) {
        return <Loader/>
    }

    return (
        <ViewContainer>
            <ImageCol lg={4} image={Cards} class={'DesktopOnly'} style={{maxWidth: "70%"}}/>
            <ContentCol lg={6} xl={5} class={'loanSummary-table'}>
                <div className="calcSummary loanSummaryWrap mt-0 ml-0">
                    <h3 className="calcSummary-blue text-center">Zvolili jste variantu:</h3>
                    <ul className="calcSummary-list">
                        <li className="calcSummary-listItem pb-4 border-bottom-0">
                            <div className="d-flex justify-content-between mb-2 mb-lg-1">
                                <span className="text-normal textCap">Výše úvěru:</span>
                                <span className={"text-bold "}>{loanController.loan.loan_selection.selected_price},-</span>
                            </div>
                            <div className="d-flex justify-content-between mb-2 mb-lg-1">
                                <span className="text-normal textCap">Poplatek za poskytnutí úvěru:</span>
                                <span className={"text-bold "}>{
                                    loanController.loan.loan_selection.selected_price > 8000
                                        ? "poplatek bude stanoven individuálně"
                                        : loanController.loan.loan_selection.loan_price + ",-"
                                }</span>
                            </div>
                            <div className="d-flex justify-content-between mb-2 mb-lg-1">
                                <span className="text-normal textCap">Doba splatnosti:</span>
                                <span className={"text-bold "}>{loanController.loan.loan_selection.selected_days} dní</span>
                            </div>
                        </li>

                        {loanController.loan.loan_selection.selected_price < 9000 && (
                            <li className={props.loanDaysAmount > 35
                                ? "calcSummary-listItem"
                                : "calcSummary-listItem border-bottom-0"}>
                                {props.loanAmount > 8000
                                    ? null
                                    : (<div className={"pencil-box mb-2 mb-lg-1"} onClick={openAddMoreDaysModal}>
                                            <span className=" text-underline">{loanController.loan.loan_selection.selected_days > 35
                                                ? 'UPRAVIT DNY NAVÍC'
                                                : "ZÍSKEJTE DELŠÍ SPLATNOST!"}</span>
                                        <img src={moreDaysToDay} className="btn-icon-more-days" alt=""/>
                                    </div>)}

                                {oldDaysAmount > 35 && (
                                    <>
                                        <div className="d-flex justify-content-between mb-2 mb-lg-0">
                                            <span className="text-small">Přidáno dnů splatnosti navíc:</span>
                                            <span className={"text-small "}>{oldDaysAmount} dnů</span>
                                        </div>
                                        <div className="d-flex justify-content-between mb-2 mb-lg-1">
                                            <span className="text-normal textCap">Poplatek za dny splatnosti navíc:</span>
                                            <span className={"text-bold "}>{
                                                loanController.loan.loan_selection.selected_price > 8000
                                                    ? 'bude stanoven individuálně'
                                                    :  getDeferralFee(loanController, oldDaysAmount, loanController.loan.loan_selection.selected_price) + ',-'
                                            }</span>
                                        </div>
                                    </>
                                )}
                            </li>
                        )}

                        <li className="calcSummary-listItem ">
                            <div className="d-flex justify-content-between mb-2 mb-lg-1">
                                <span className="text-bold textCap">Celková doba splatnosti:</span>
                                <span className="text-bold textCap">{loanController.loan.loan_selection.selected_days} dní</span>
                            </div>
                            <div className="d-flex justify-content-between mb-2 mb-lg-1">
                                <span className="text-bold textCap text-left">Celkem zaplatíte:</span>
                                <span className="text-bold textCap">{
                                    oldDaysAmount !== 0 && loanController.loan.loan_selection.deferral_fee
                                        ? loanController.loan.loan_selection.total_loan_price
                                        : loanController.loan.loan_selection.total_loan_price || "celková cena bude stanovena"
                                }</span>
                            </div>
                            <div className="d-flex justify-content-between mb-2 mb-lg-1">
                                <span className="text-bold text-left textCap">Doba splatnosti úvěru nastane:</span>
                                <span className="text-bold textCap">{getPaymentDate(null, loanController.loan.loan_selection.selected_days)}</span>
                            </div>
                            <div className="d-flex justify-content-between mb-2 mb-lg-1">
                                <span className="text-normal">RPSN:</span>
                                <span className="text-bold">{
                                    loanController.loan.loan_selection.selected_price > 8000
                                        ? '0'
                                        : calc_rpsn(
                                            loanController.loan.loan_selection.selected_price,
                                        loanController.loan.loan_selection.deferral_fee + loanController.loan.loan_selection.loan_price,
                                        loanController.loan.loan_selection.selected_days)}%</span>
                            </div>
                        </li>
                    </ul>
                </div>
                <div className="loanSummary-blueBox">
                    <div className="d-flex">
                        <img src={HandsTime} alt=""/>
                        <p className="mb-0">Žádost předschválíme <b>do 1 minuty</b></p>
                    </div>
                </div>
                {loanController.loan.loan_selection.selected_price < 9000 && (
                    <div className={loanController.loan.loan_selection.selected_days === 70 ? "loanSummary-info d-flex none mb-5" : "loanSummary-info d-flex mb-5"}>
                        <img src={Info} className="notification-icon" alt=""/>
                        <p className="notification-text">
                            Datum splatnosti si můžete i jednoduše odložit třeba <b>o </b><b>
                            {70 - loanController.loan.loan_selection.selected_days} dnů za {deferredPrice?.amount},-</b> .
                            Pak by splatnost nastala <b>{getPaymentDate(null, loanController.loan.loan_selection.selected_days)}</b>.
                        </p>
                    </div>
                )}
            </ContentCol>
        </ViewContainer>
    );
};

export default CalculationSummary;