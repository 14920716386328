import React from 'react';
import clsx from "clsx";
import {useTypedSelector} from "helpers/reducers";
import {VerificationQuestionsEntity} from "types/entities";
import {getQuestionType} from "shared/utility";
import {SessionControllerState} from "store/sessionController/SessionControllerReducer";

interface QuestionProps {
    onClick: (e: any, name: string, answer: boolean) => void,
    question: any,
    questionName: string,
    successMessage?: string,
    failMessage?: string,
    type: string
}

const Question = (props: QuestionProps) => {
    const verificationQuestions: VerificationQuestionsEntity = useTypedSelector(state => state.sessionController.verificationQuestions);
    const otherIncome = useTypedSelector(state => state.sessionController.spendingReport?.other_income);
    const mailingAddress = useTypedSelector(state => state.sessionController.mailingAddress?.has_mailing_address);

    let questionType;
    let condition;
    if (props.type === 'verification') {
        questionType = getQuestionType(props.questionName, verificationQuestions);
        condition = questionType || questionType === false;
    } else if (props.type === 'other_income') {
        questionType = otherIncome;
        condition = otherIncome || otherIncome === false;
    } else {
        questionType = mailingAddress;
        condition = false
    }


    return (
        <li className="calcSummary-listItem d-flex justify-content-between Question-listItem">
            <div className="Question-question">
                <h2>{props.question}</h2>
                <div className={clsx("notificationWrap", condition && "showNotifSmall")}>
                    <span className={clsx("notification", condition && "showNotification")}>
                        {props.type === 'other_income'
                            ? (
                                questionType
                                    ? props.successMessage
                                    : props.failMessage
                            ) : (
                                questionType
                                    ? props.failMessage
                                    : props.successMessage
                            )
                        }
                    </span>
                </div>
            </div>
            <div className="Question-questionButtons">
                <label
                    onClick={(e) => props.onClick(e, props.questionName, true)}
                    className={clsx("Question-btn" , questionType && "Question-btn__active")}>
                    <input
                        type="radio"
                        name="radio1"
                        value="Ano"
                        checked={questionType !== undefined}
                    />ANO
                </label>
                <label
                    onClick={(e) => props.onClick(e, props.questionName, false)}
                    className={clsx("Question-btn" , questionType === false && "Question-btn__active")}>
                    <input
                        type="radio"
                        name="radio1"
                        value="Ne"
                        checked={questionType !== undefined}
                    />NE
                </label>
            </div>
        </li>
    );
};

export default Question;