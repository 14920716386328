import React from 'react';
import clsx from "clsx";

interface NotificationProps {
    imageUrl: string,
    class?: string,
    show?: boolean,
    imgClass?: string,
    alt?: string,
    children: React.ReactNode | React.ReactNode[]
}

const Notification = (props: NotificationProps) => {
    return (
        <div className={clsx("notification d-flex", props.show ? 'opacity-1' : 'opacity-0 pointer-events-none' , props.class, )}>
            <img src={props.imageUrl} className={clsx("notification-icon", props.imgClass)} alt={props.alt}/>
            <p className={"notification-text "}>{props.children}</p>
        </div>
    );
};

export default Notification;