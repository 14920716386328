import {createAction} from "helpers/createAction";
import ajax from "helpers/ajax";
import {EmailExistsResponse, UserResponse} from "types/responses";
import {QuestionAnswer, TerminateStatusObject, UserAccountItem} from "types/entities";
import {SpendingReportItem} from "types/entities/SpendingReport";
import {MailingAddressItem} from "types/entities/MailingAddress";
import AppControllerAction from "store/appControler/AppControllerAction";

interface LustratedData {
    nin: string,
    ico: string,
    loan_amount: number,
    answers: boolean[]
}

class SessionControllerAction {
    static readonly SET_USER = "SessionControllerAction.SetUser";
    static readonly SET_BIRTH_NUMBER = "SessionControllerAction.SetBirthNumber";
    static readonly SET_USER_EXISTS = "SessionControllerAction.SetUserExists";
    static readonly SET_USER_QUESTION = "SessionControllerAction.SetUserQuestion";
    static readonly SET_USER_SPENDING_REPORT = "SessionControllerAction.SetUserSpendingReport";
    static readonly SET_QUESTION_TERMINATE_STATUS = "SessionControllerAction.SetQuestionsTerminateStatus";
    static readonly SET_USER_MAILING_ADDRESS = "SessionControllerAction.SetUserMailingAddress";
    static readonly SET_USER_EMAIL_EXISTS = "SessionControllerAction.SetUserEmailExists";
    static readonly SET_USER_ACCOUNT = "SessionControllerAction.setUserAccount";
    static readonly SET_LUSTRATIONS = "SessionControllerAction.setLustrations";
    static readonly SET_GA_ID = "SessionControllerAction.setGaId";
    static readonly RESET_SESSION_DATA = "SessionControllerAction.ResetSessionData";
    /**
     * @function verify user in rzp, inter db and fetch everything about user: ICO, birt_number, address
     *
     * @param verificationData: {first_name: string, last_name: string, birth_code: string}
     */
    static fetchUser = (verificationData: {first_name: string, last_name: string, birth_code: string}) => {
        return dispatch => {
            return ajax.get('/api/get-company/?birth_number=' + verificationData.birth_code
                + '&first_name=' + verificationData.first_name
                + '&last_name=' + verificationData.last_name).then(r => {
                dispatch(SessionControllerAction.setUser(r.data));
                return r;
            });
        }
    }

    /**
     * @function - verify if user (account) exist in inter db (already registered)
     *
     * @param birthCode: string
     */
    static isUserExists = (birthCode: string) => {
        return dispatch => {
            return ajax.get('/api/user-exists/?birth_number=' + birthCode).then(r => {
                dispatch(SessionControllerAction.setUserExists(r.data));
                return r;
            })
        }
    }

    /**
     * @function - verify question on server - if user already passed "Question Step" in registration in past,
     *             check if questions' answers are same or different.
     *
     * @issue - maybe this isn't the best idea make compare answers from past,
     *          because of time horizon - could not be sufficient or could be over
     *
     * @param birth_number: string
     * @param question: string
     * @param answer: boolean
     */
    static verifyQuestion = (birth_number: string, question: string, answer: boolean) => {
        return dispatch => {
            return ajax.post("/api/registration-answers/", {
                birth_number,
                question,
                answer
            }).then(r => {
                dispatch(SessionControllerAction.setQuestionTerminateStatus({question: question, status: r.data.terminate}))
                return r;
            })
        }
    }


    /**
     * @function - verify email in "Register account" step - check if email already exists in inter db
     *
     * @param email: string
     */
    static verifyUserEmail = (email: string) => {
        return dispatch => {
            return ajax.get('/api/user-exists/?email=' + email).then(r => {
                dispatch(SessionControllerAction.setUserEmailExists(r.data));
                return r;
            })
        }
    }

    static fetchLustrations = (data: LustratedData) => {
        return dispatch => {
            return ajax.post('/api/lustrate-not-registered/', data).then(r => {
                console.log(r)
                dispatch(SessionControllerAction.setLustrations(r.data));
                return r;
            })
        }
    }

    static setGaId = (gaId) => {
        return createAction(SessionControllerAction.SET_GA_ID, gaId);
    }

    static setUser = (user: UserResponse) => {
        return createAction(SessionControllerAction.SET_USER, user);
    }

    static setUserBirthNumber = (birthNumber: string) => {
        return createAction(SessionControllerAction.SET_BIRTH_NUMBER, birthNumber);
    }

    static setUserExists = (user: UserResponse) => {
        return createAction(SessionControllerAction.SET_USER_EXISTS, user);
    }

    static setUserQuestion = (answer: QuestionAnswer) => {
        return createAction(SessionControllerAction.SET_USER_QUESTION, answer);
    }

    static setUserSpendingReport = (report: SpendingReportItem) => {
        return createAction(SessionControllerAction.SET_USER_SPENDING_REPORT, report);
    }

    static setUserMailingAddress = (mailingAddress: MailingAddressItem) => {
        return createAction(SessionControllerAction.SET_USER_MAILING_ADDRESS, mailingAddress);
    }

    static setUserEmailExists = (email: EmailExistsResponse) => {
        return createAction(SessionControllerAction.SET_USER_EMAIL_EXISTS, email);
    }

    static resetSessionData = () => {
        return createAction(SessionControllerAction.RESET_SESSION_DATA, null);
    }

    static setQuestionTerminateStatus = (status: TerminateStatusObject) => {
        return createAction(SessionControllerAction.SET_QUESTION_TERMINATE_STATUS, status);
    }

    static setUserAccount = (userAccountItem: UserAccountItem) => {
        return createAction(SessionControllerAction.SET_USER_ACCOUNT, userAccountItem)
    }

    static setLustrations = (lustrations) => {
        return createAction(SessionControllerAction.SET_LUSTRATIONS, lustrations);
    }
}

export default SessionControllerAction;