import React, {useEffect, useState} from 'react';
import Info from "assets/UserAssets/info.svg";
import EmptyInbox from "assets/UserAssets/emptyInbox.svg";
import ViewContainer from "components/ViewContainer";
import ContentCol from "components/ContentCol";
import {useDispatch} from "useDispatch";
import SessionControllerAction from "store/sessionController/SessionControllerAction";
import clsx from "clsx";
import {useTypedSelector} from "helpers/reducers";
import PurposeItemContainer from "pages/purpose/components/PurposeItemContainer";
import PurposeItem from "pages/purpose/components/PurposeItem";
import True from 'assets/UserAssets/true.svg'
import useFoxentryAddressWhisper from "helpers/hooks/foxentry/useFoxentryAddressWhisper";
import {useForm} from "react-hook-form";

const Address = props => {
    const dispatch = useDispatch();
    const address = useTypedSelector(state => state.sessionController.user?.address);
    const mailingAddress = useTypedSelector(state => state.sessionController.mailingAddress);
    const [activeOptionsValue, setActiveOptionsValue] = useState("");
    const [addressGoogle, setAddressGoogle] = React.useState("");
    const [error, setError] = useState(false);
    const {register, setValue} = useForm();

    const {response,
        showWhisperer,
        setShowWhisperer,
        debounceEventHandler,
        fetchAddress} = useFoxentryAddressWhisper(addressGoogle, "whisper", '/location/search');

    useEffect(() => {
        if (mailingAddress?.has_mailing_address !== null) {
            if (mailingAddress?.has_mailing_address) {
                setActiveOptionsValue("Ano")
            } else {
                setActiveOptionsValue("Ne")
            }
        }
    }, [activeOptionsValue]);

    useEffect(() => {
        if (mailingAddress.has_mailing_address) {
            setValue('address', mailingAddress.address);
        }
    }, []);

    const onQuestionAnswerHandler = (event) => {
        const value = event.target.value === 'Ano';
        dispatch(SessionControllerAction.setUserMailingAddress({
            name: "has_mailing_address",
            value: value
        }))
        setActiveOptionsValue(event.target.value)
    }

    const handleChange = (event) => {
        fetchAddress('/location/search', event.target.value);
    }

    const handleOnclick = (data) => {
        setShowWhisperer(false);
        if (!data.full) {
            setError(true)
        }

        if (!data.street || !data.city || !data?.number?.full) {
            setError(true);
            return;
        }

        setError(false);
        setValue('address',`${data.street} ${data.number.full}, ${data.city} ${data.zip}`);

        dispatch(SessionControllerAction.setUserMailingAddress({name: 'street', value: data.street}));
        dispatch(SessionControllerAction.setUserMailingAddress({name: 'house_number', value: data.number.full}));
        dispatch(SessionControllerAction.setUserMailingAddress({name: 'city', value: data.city}));
        dispatch(SessionControllerAction.setUserMailingAddress({name: 'postal_code', value: data.zip}));
        dispatch(SessionControllerAction.setUserMailingAddress({name: 'address', value: `${data.street} ${data.number.full}, ${data.city} ${data.zip}`}));
    }
    
    const handleBlur = () => {
        setTimeout(() => {
            setShowWhisperer(false);
        }, 550);
    }

    return (
        <ViewContainer>
            <ContentCol lg={6} class={'addressOrder-2'}>
                <form style={{position: 'relative', zIndex: '1'}}>
                    <ul className="calcSummary-list Question-list Purpose-list">

                        <PurposeItemContainer title={<><b>Poštu mi prosím zasílejte jinam</b> - mám jinou korespondenční adresu</>}>
                            <PurposeItem
                                name={'purpose2'}
                                value={'Ano'}
                                objectItem={'has_mailing_address'}
                                label={'Ano'}
                                activeValue={activeOptionsValue}
                                setActiveValue={setActiveOptionsValue}
                                onClick={onQuestionAnswerHandler}/>
                            <div className={clsx("otherInputWrap", mailingAddress?.has_mailing_address && "show")} style={error && activeOptionsValue === 'Ano' ? {marginBottom: '60px'} : {marginBottom: '0px'}}>
                                <input type={"text"} {...register('address')} onChange={debounceEventHandler(handleChange, 550)} onBlur={handleBlur} />
                                {showWhisperer && response?.results?.length && (
                                    <div>
                                        {response?.results?.map((result, index) => (
                                            <div
                                                key={index}
                                                onClick={() => handleOnclick(result.data)}
                                                className={'autocomplete'}>
                                                {`${result.data.street} ${result.data.number ? result.data.number.full : ""} ${result.data.city ? result.data.city : ""} ${result.data.zip ? result.data.zip : ""}`}</div>
                                        ))}
                                    </div>
                                )}

                                {error && (
                                    <p className={"error address-error"}>
                                        Zadejte adresu ve správnem formátu: ulice číslo domu, město
                                    </p>
                                )}

                            </div>
                            <PurposeItem
                                name={'purpose2'}
                                value={'Ne'}
                                objectItem={'has_mailing_address'}
                                label={'Ne'}
                                activeValue={activeOptionsValue}
                                setActiveValue={setActiveOptionsValue}
                                onClick={onQuestionAnswerHandler}/>
                        </PurposeItemContainer>
                    </ul>
                </form>
                <div className="loanSummary-info address-info d-flex">
                    <img src={Info} className="notification-icon" alt=""/>
                    <p className="notification-text">
                        <b>Patříme k 10 nejinovativnějším aplikacím</b> českého internetu v roce 2020 v oblasti financí
                    </p>
                </div>
            </ContentCol>
            <ContentCol lg={4} class={'addressOrder-1'}>
                <img src={EmptyInbox} className="empty-inbox DesktopOnly" alt=""/>
                <div className="calcSummary mt-0 ml-0" style={{zIndex: 2, position: 'relative'}}>
                    <h3 className="calcSummary-blue">Adresa pro zasílání pošty je:</h3>
                    <div className="d-flex calcSummary-listItem calcSummary-listItem1" style={{borderTop: '1px solid rgba(204, 223, 229, 0.8)', borderBottom: "none"}}>
                        <img src={True} className={'trueIcon'} alt=""/>
                        <span className="text-bold text-left">{
                            mailingAddress.has_mailing_address
                                ? mailingAddress?.address
                                : address?.street
                            + " " + address?.house_number
                            + ", " + address?.city
                            + ", " + address?.postal_code}
                        </span>
                    </div>
                </div>
            </ContentCol>
        </ViewContainer>
    );
};

export default Address;