import React from 'react';
import clsx from "clsx";

interface FooterNavItemProps {
    class?: string,
    children: React.ReactNode | React.ReactNode[]
}

const FooterNavItem = (props: FooterNavItemProps) => {
    return (
        <li className={clsx("footerProgress-listItem", props.class)}>
            {props.children}
        </li>
    );
};

export default FooterNavItem;