import React, {useEffect, useState} from 'react';
import ButtonPurple from "shared/UI/Buttons/ButtonPurple/ButtonPurple";
import ButtonWhite from "shared/UI/Buttons/ButtonWhite/ButtonWhite";
import Deadline from "assets/UserAssets/deadline.svg";
import CalculationSlider from "pages/calculation/components/CalculationSlider";
import {useTypedSelector} from "helpers/reducers";
import {PriceListItem} from "types/entities";
import {LoanReducerState} from "store/loan/LoanReducer";
import Loader from "components/Loader";
import {useDispatch} from "useDispatch";
import LoanAction from "store/loan/LoanAction";
import {closeModal} from "store/action";
import Button from "components/Button";
import {getDeferralFee} from "shared/utility";

interface CalculationAddDaysProps {
    setNewDaysAmount:  React.Dispatch<any>;
}


const CalculationAddDays = (props: CalculationAddDaysProps) => {
    const loanController: LoanReducerState = useTypedSelector(state => state.loanController);
    const [rangeDays, setRangeDays] = useState<number | number[]>(loanController.loan.loan_selection.selected_days);
    const daysMarks = [{value: 19.6},{value: 25.2},{value: 30.8},{value: 36.4},{value: 42},{value: 47.6},{value: 53.2},{value: 58.8},{value: 64.4}]
    const change = true;
    const dispatch = useDispatch();

    const [loanPrices, setLoanPrices] = useState({
        amount: loanController?.loan.loan_selection.selected_price,
        loan_price: loanController?.loan.loan_selection.loan_price,
        deferred_price: loanController?.loan.loan_selection.deferral_fee,
        total_price: loanController?.loan.loan_selection.total_loan_price,
    });

    useEffect(() => {
        const priceItem: PriceListItem = loanController?.priceList?.find(price => price.amount === loanController.loan.loan_selection.selected_price);
        if (priceItem?.amount > 8000) {
            setLoanPrices({
                ...loanPrices,
                loan_price: null,
                total_price: null,
                deferred_price: null,
            })
        } else {
            const loanPrice =
                priceItem?.loan_prices?.find(price => {
                    if (rangeDays <= 35) {
                        return price?.days === rangeDays
                    } else {
                        return price?.days === 35
                    }
                });
            const deferredPrice = priceItem?.deferral_prices?.find(price => price?.days === (+rangeDays - 35));
            const totalPrice = deferredPrice !== undefined ?
                loanPrice?.amount + loanController.loan.loan_selection.selected_price + deferredPrice?.amount
                : loanPrice?.amount + loanController.loan.loan_selection.selected_price;

            setLoanPrices({
                ...loanPrices,
                amount: priceItem?.amount,
                loan_price:  loanPrice?.amount,
                deferred_price: deferredPrice?.amount || null,
                total_price: totalPrice,
            })
        }
    }, [loanController.loan.loan_selection.selected_price, rangeDays]);

    if (!loanController) {
        return <Loader/>
    }

    const onAddNewDaysAmountHandler = () => {
        if (rangeDays <= 35) {
            props.setNewDaysAmount(0);
        } else {
            props.setNewDaysAmount(+rangeDays - 35);

        }

        dispatch(LoanAction.setLoanSelection({
            ...loanController.loan.loan_selection,
            selected_days: rangeDays,
            loan_price: loanPrices?.loan_price,
            deferral_fee: loanPrices?.deferred_price,
            total_loan_price: loanController.loan.loan_selection.selected_price + loanPrices.loan_price + loanPrices?.deferred_price
        }))
        dispatch(closeModal());
    }

    const onCancelAddMoreDaysHandler = () => {
        dispatch(closeModal());
    }

    return (
        <div className="CalculationAddDays">
            <h1 className="summary-login-modalHeading">Přidat/odebrat dny splatnosti</h1>
            <div className="text-small">
                <div className="d-block">
                    Máte aktuálně zvolenou dobu splatnosti úvěru:
                </div>
                <div className="d-wrap">
                    <b>{rangeDays} dnů </b>
                    za <b id="text-purple" className="text-purple">{
                        rangeDays <= 35
                            ? loanPrices?.loan_price
                            : loanController?.loan.loan_selection.loan_price + loanPrices?.deferred_price} Kč</b>.
                </div>
            </div>
            <div className="input-range__wrapper">
                <CalculationSlider
                    defaultValue={loanController.loan.loan_selection?.selected_days || 35}
                    marks={daysMarks}
                    max={70}
                    min={14}
                    step={7}
                    days={true}
                    type={'fix'}
                    setSliderValue={setRangeDays}/>
            </div>
            {change
                ?  <div className="btnsWrap d-flex flex-column">
                    <Button
                        class={'btn-purple m-0 mb-3'}
                        onClick={onAddNewDaysAmountHandler}>
                        <span>Potvrdit změnu a pokračovat</span>
                    </Button>
                    <Button
                        class={'btn-main btn-whiteSetup m-0'}
                        onClick={onCancelAddMoreDaysHandler}>
                        <span>Nechci menit počet dnů splatnosti</span>
                        <span className="small">Rozmyslel/a jsem si to</span>
                    </Button>
                </div>
                : (
                    <Button
                        class={'btn-main btn-whiteSetup m-0'}
                        onClick={onCancelAddMoreDaysHandler}>
                        <span>Nechci upravovat počet dnů splatnosti</span>
                        <span className="small">Rozmyslel/a jsem si to</span>
                    </Button>
                )
            }
            <img src={Deadline} className="Keys" alt=""/>
        </div>
    );
};

export default CalculationAddDays;