import React, {useEffect, useState} from 'react';
import Listening from "assets/UserAssets/listening.svg";
import Info from "assets/UserAssets/info.svg";
import ViewContainer from "components/ViewContainer";
import ImageCol from "components/ImageCol";
import ContentCol from "components/ContentCol";
import PurposeItemContainer from "pages/purpose/components/PurposeItemContainer";
import PurposeItem from "pages/purpose/components/PurposeItem";
import Information from "components/Information";
import {LoanPurpose} from "types/entities";
import {useTypedSelector} from "helpers/reducers";
import ScrollToTop from "components/ScrollToTop";

const PurposeRepeat = props => {
    const loanPurpose: LoanPurpose = useTypedSelector(state => state.loanController.purpose);
    const [activeValue, setActiveValue] = useState(loanPurpose.recurring_debt || "");

    return (
        <ViewContainer>
            <ImageCol lg={3} image={Listening}/>
            <ContentCol lg={7}>
                <PurposeItemContainer title={<>Budete potřebovat <b>opakovaný úvěr</b>, nebo je to <b>jednorázovka?</b></>}>
                    <PurposeItem
                        name={'purpose3'}
                        value={'Ano - (plánuji si půjčovat pravidelně)'}
                        objectItem={'recurring_debt'}
                        label={'Ano - (plánuji si půjčovat pravidelně)'}
                        activeValue={activeValue}
                        setActiveValue={setActiveValue}/>
                    <PurposeItem
                        name={'purpose3'}
                        value={'Ne'}
                        objectItem={'recurring_debt'}
                        label={'Ne'}
                        activeValue={activeValue}
                        setActiveValue={setActiveValue}/>
                </PurposeItemContainer>

                <Information text={"Prohlašuji, že mnou zvolené odpovědi jsou pravdivé!"}/>

            </ContentCol>
        </ViewContainer>
    );
};

export default PurposeRepeat;