import {createAction} from "helpers/createAction";
import ajax from "helpers/ajax";
import {Agreement, LoanConfig, LoanPurposeItem, LoanSelection, PriceList} from "types/entities";
import {create} from "domain";

class LoanAction {
    static readonly SET_LOAN_CONFIG = "LoanAction.SetLoanConfig";
    static readonly SET_PRICE_LIST = "LoanAction.SetPriceList";
    static readonly SET_LOAN_SELECTION = "LoanAction.SetLoanSelection";
    static readonly SET_LOAN_SELECTION_DAYS = "LoanAction.SetLoanSelectionDays";
    static readonly SET_LOAN_SELECTION_PRICE = "LoanAction.SetLoanSelectionPrice";
    static readonly SET_LOAN_PURPOSE = "LoanAction.SetLoanPurpose";
    static readonly SET_AGREEMENT = "LoanAction.SetAgreement";
    static readonly RESET_LOAN_DATA = "SessionController.ResetLoanData";

    static fetchPriceList = (type: "fix" | "rent") => {
        return dispatch => {
            return ajax.get("/api/price-list/" + type).then(r => {
                dispatch(LoanAction.setPriceList(r.data))
            }).catch(error => {
                console.error(error.message)
            });
        }
    }

    static sendRequest = (data) => {
        return dispatch => {
            return ajax.post("/api/registration-finish/", data).then(r => {
                return r;
            });
        }
    }


    static setLoanConfig = (loan_config: LoanConfig) => {
        return createAction(LoanAction.SET_LOAN_CONFIG, loan_config);
    }

    static setPriceList = (priceList: PriceList) => {
        return createAction(LoanAction.SET_PRICE_LIST, priceList)
    }

    static setLoanSelection = (selection: LoanSelection) => {
        return createAction(LoanAction.SET_LOAN_SELECTION, selection)
    }

    static setSelectionDays = (days: number) => {
        return createAction(LoanAction.SET_LOAN_SELECTION_DAYS, days);
    }

    static setSelectionPrice = (price: number) => {
        return createAction(LoanAction.SET_LOAN_SELECTION_PRICE, price);
    }

    static setLoanPurpose = (purpose: LoanPurposeItem) => {
        return createAction(LoanAction.SET_LOAN_PURPOSE, purpose);
    }

    static setAgreement = (agreement: Agreement) => {
        return createAction(LoanAction.SET_AGREEMENT, agreement);
    }

    static resetLoanData = () => {
        return createAction(LoanAction.RESET_LOAN_DATA, null);
    }

}

export default LoanAction;