import React from 'react';

interface PurposeItemContainerProps {
    children: React.ReactNode | React.ReactNode[],
    title: any,
    subtitle?: string
}

const PurposeItemContainer = (props: PurposeItemContainerProps) => {
    return (
        <li className="calcSummary-listItem Question-listItem">
            <div className="Question-question Purpose">
                <h2>{props.title}</h2>
                <span className="Purpose-subheading">{props.subtitle}</span>
            </div>
            <div className="Purpose-purposeButtons">
                {props.children}
            </div>
        </li>
    );
};

export default PurposeItemContainer;