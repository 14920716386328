import LoanAction from "store/loan/LoanAction";
import {
    Agreement,
    Loan,
    LoanConfig,
    LoanPurpose,
    LoanPurposeItem,
    LoanSelection,
    PriceListItem,
    SendRequest
} from "types/entities";
import update from "immutability-helper";

interface LoanReducerSetLoanConfigAction {
    payload: LoanConfig,
    type: typeof LoanAction.SET_LOAN_CONFIG
}

interface LoanReducerSetPriceListAction {
    payload: PriceListItem[],
    type: typeof LoanAction.SET_PRICE_LIST
}

interface LoanReducerSetLoanSelectionAction {
    payload: LoanSelection,
    type: typeof LoanAction.SET_LOAN_SELECTION
}

interface LoanReducerSetLoanSelectionDaysAction {
    payload: number,
    type: typeof LoanAction.SET_LOAN_SELECTION_DAYS
}

interface LoanReducerSetLoanSelectionPriceAction {
    payload: number,
    type: typeof LoanAction.SET_LOAN_SELECTION_PRICE
}

interface LoanReducerResetLoanDataAction {
    payload: any,
    type: typeof LoanAction.RESET_LOAN_DATA
}

interface LoanReducerSetAgreementAction {
    payload: Agreement,
    type: typeof LoanAction.SET_AGREEMENT
}

interface LoanReducerSetLoanPurposeAction {
    payload: LoanPurposeItem,
    type: typeof LoanAction.SET_LOAN_PURPOSE
}

type LoanReducerAction =
    LoanReducerSetLoanConfigAction
    | LoanReducerSetPriceListAction
    | LoanReducerSetLoanSelectionAction
    | LoanReducerSetLoanSelectionDaysAction
    | LoanReducerSetLoanSelectionPriceAction
    | LoanReducerResetLoanDataAction
    | LoanReducerSetLoanPurposeAction
    | LoanReducerSetAgreementAction;

export interface LoanReducerState {
    priceList: PriceListItem[],
    loan: Loan,
    purpose: LoanPurpose,
    sendRequest: SendRequest,
}

class LoanReducer {
    initialState: LoanReducerState = {
        priceList: [],
        loan: null,
        purpose: null,
        sendRequest: null,
    }

    reducer = (state: LoanReducerState = this.initialState, action: LoanReducerAction): LoanReducerState => {
        switch (action.type) {
            case LoanAction.SET_LOAN_CONFIG:
                return update(state, {
                    loan: {
                        $set: action.payload
                    }
                })

            case LoanAction.SET_PRICE_LIST:
                return update(state, {
                   priceList: {
                       $set: action.payload
                   }
                });

            case LoanAction.SET_LOAN_SELECTION:
                return update(state, {
                    loan: {
                        loan_selection: {
                            $set: action.payload
                        }
                    }
                });

            case LoanAction.SET_LOAN_SELECTION_DAYS:
                return update(state, {
                    loan: {
                        loan_selection: {
                            selected_days: {
                                $set: action.payload
                            }
                        }
                    }
                })

            case LoanAction.SET_LOAN_SELECTION_PRICE:
                return update(state, {
                    loan: {
                        loan_selection: {
                            selected_price: {
                                $set: action.payload
                            }
                        }
                    }
                })

            case LoanAction.RESET_LOAN_DATA:
                return {
                    ...state,
                    loan: null
                }

            case LoanAction.SET_LOAN_PURPOSE:
                return {
                    ...state,
                    purpose: {
                        ...state.purpose,
                        [action.payload.name]: action.payload.value
                    }
                }

            case LoanAction.SET_AGREEMENT:
                return {
                    ...state,
                    sendRequest: {
                        ...state.sendRequest,
                        [action.payload.name]: action.payload.value
                    }
                }

            default:
                return state;
        }
    }
}

export default LoanReducer;