import React, {useEffect, useState} from 'react';
import ViewContainer from "components/ViewContainer";
import ImageCol from "components/ImageCol";
import ContentCol from "components/ContentCol";
import ThumbUp from "assets/UserAssets/thumbUp.svg";
import True from "assets/UserAssets/finalTimeIcon.svg";
import Info from "assets/UserAssets/info.svg";
import Button from "components/Button";
import {CLIENT_URL} from "helpers/conifg";

const Finish = props => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <ViewContainer class={"Finish-container"}>
            <ImageCol lg={3} image={ThumbUp}/>
            <ContentCol lg={7}>
                <div className="verifyMessageSuccess">
                    <div className="verifyMessageSuccess-wrap d-flex">
                        <img src={True} alt=""/>
                        <div className="textBlackWrap d-block">
                            <div className="d-flex">
                                <h2>Hotovo!</h2>
                                <p className="text-orange">systém čeká na ověření vaší identity</p>
                            </div>
                            <p className="textBlack"><b>Registrace proběhla na jedničku! <br/> Nyní se prosím přihlaste k účtu a doložte požadované dokumenty (kopie op + výpis z b.ú.)</b></p>
                        </div>
                    </div>
                    <h3>Co se bude dít dále?</h3>
                    <p className="mt-0">Odešleme Vám SMS a e-mail:</p>
                    <ul className="finishList">
                        <li>s potvrzením o registraci</li>
                        <li>ihned Vám žádost před-schválíme</li>
                    </ul>
                    <div className="d-flex cuountdown">
                        <a rel={'noreferrer'} href={CLIENT_URL}>
                            <Button class={"btn btn-purple"}>Přihlásit se k účtu</Button>
                        </a>
                    </div>
                    <ul className="finishList">
                        <li><b>vytvoříme Vám účet</b> ve kterém uvidíte své úvěry, osobní údaje a vše co jste zadali při
                            odeslání žádosti. Pomocí účtu budete pomoci i elektronicky podepisovat smlouvy a pod.</li>
                        <li><b>operátor si ihned převezme Vaši žádost</b> a bude ji zpracovávat - o tom Vás taky budeme informovat a v případě SCHVÁLENÍ ÚVĚRU a po podpisu dokumentů Vám <b>ihned odešleme peníze na účet</b></li>
                    </ul>
                </div>
                <div className="loanSummary-info address-info d-flex mt-4">
                    <img src={Info} className="notification-icon" alt=""/>
                    <p className="notification-text mb-0">
                        <b>O další úvěry už budete žádat přímo ze svých účtu a tento proces již nebudete opakovat.</b>
                    </p>
                </div>
            </ContentCol>
        </ViewContainer>
    );
};

export default Finish;