import React from 'react'

const Auxiliary = props => {
    return (
       <div className={props.class}>
           {props.children}
       </div>
    );
};

export default Auxiliary;