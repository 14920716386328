import React, {useEffect} from 'react';
import CookieRow from "components/Cookies/components/CookieRow";
import {useForm} from "react-hook-form";
import Button from "components/Button";
import {useDispatch} from "useDispatch";
import {closeModal} from "store/action";
import AppControllerAction from "store/appControler/AppControllerAction";
import {useTypedSelector} from "helpers/reducers";
import {Cookies_status} from "types/enums/Cookies_status";
import CookiesImg from "assets/UserAssets/cookies.svg";

interface Props {}

const CookiesModal = (props: Props) => {
    const dispatch = useDispatch();
    const cookies = useTypedSelector(state => state.appController.cookies);
    const { register, handleSubmit, setError, watch, formState: { errors } } = useForm({
        defaultValues: {
            tech_cookies: true,
            analytics_cookies: false,
            advert_cookies: false,
        }
    });


    const sendCookies = () => {
        dispatch(AppControllerAction.sendCookies(cookies));
    }

    const submitAllHandler = data => {
        data.tech_cookies = true;
        data.analytics_cookies = true;
        data.advert_cookies = true;
        dispatch(AppControllerAction.setCookies({
            ...cookies,
            ...data,
            status: Cookies_status.AGREED
        }));
        dispatch(closeModal());
    }

    const submitHandler = data => {
        data.tech_cookies = true;
        dispatch(closeModal());
        dispatch(AppControllerAction.setCookies({
            ...cookies,
            ...data,
            status: Cookies_status.AGREED
        }));
        sendCookies();
        dispatch(closeModal());
    }

    return (
        <div className="CookiesModal">
            <div className="d-flex flex-wrap justify-content-center">
                <img src={CookiesImg} className={"mr-3"} alt="cookies icon"/>
                <h1>Cookies - Nastavení</h1>
            </div>
            <p>Zde máte možnost přizpůsobit soubory cookie podle kategorií, v souladu s vlastními preferencemi.</p>
            <form className={"mt-4"}>
                <CookieRow register={register} title={"Technické cookies"} inputName={"tech_cookies"} disabled={true}>
                    jsou nezbytné pro správné fungování webu a všech funkcí, které nabízí. S touto volbou nelze nesouhlasit.
                </CookieRow>
                <CookieRow register={register}  title={"Analytické cookies"} inputName={"analytics_cookies"} disabled={false}>
                    nám umožňují měření výkonu našeho webu a našich reklamních kampaní.
                </CookieRow>
                {/*<CookieRow title={"Personalizované soubory cookie"}>*/}
                {/*    Používáme rovněž soubory cookie a další technologie, abychom přizpůsobili náš obchod potřebám a zájmům našich zákazníků a připravili tak pro Vás výjimečné nákupní zkušenosti. Díky použití personalizovaných souborů cookie se můžeme vyvarovat vysvětlování nežádoucích informací, jako jsou neodpovídající doporučení výrobků nebo neužitečné mimořádné nabídky. Navíc nám používání personalizovaných souborů cookie umožňuje nabízet Vám dodatečné funkce, jako například doporučení výrobků přizpůsobených Vašim potřebám.*/}
                {/*</CookieRow>*/}
                <CookieRow register={register} title={"Reklamní cookies"} inputName={"advert_cookies"} disabled={false}>
                    používáme my nebo naši partneři, abychom Vám mohli zobrazit vhodné obsahy nebo reklamy, jak na našich stránkách, tak na stránkách třetích subjektů.
                </CookieRow>
                <div className="d-flex justify-content-center mt-3">
                    <Button class={"btn btn-main reset-top-0"} onClick={handleSubmit(submitHandler)}>Uložit</Button>
                    <Button type={"submit"} class={"btn btn-purple reset-top-0"} onClick={handleSubmit(submitAllHandler)}>Povolit vše</Button>
                </div>
            </form>
        </div>
    );
};

export default CookiesModal;