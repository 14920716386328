import React, {useEffect, useState} from 'react';
import Backdrop from "helpers/Backdrop";
import CloseModal from "assets/UserAssets/close.svg";
import {useDispatch} from "useDispatch";
import {closeModal} from "store/action";
import {useTypedSelector} from "helpers/reducers";

interface ModalProps {}

const Modal = (props: ModalProps) => {
    const modal = useTypedSelector(state => state.modal);
    const dispatch = useDispatch();
    const [height, setHeight] = useState(0);
    const [disableClose, setDisableClose] = useState(false);
    let style;
    const [reload, setReload] = useState(performance.navigation.type);

    useEffect(() => {
        setReload(0);
    }, [performance.navigation.type]);

    useEffect(() => {
        if (modal.props?.disableClose) {
            setDisableClose(true);
        }
    }, []);

    // alert(modal.component?.props);

    useEffect(() => {
        const documentHeigt = document.documentElement.offsetHeight;
        setHeight(documentHeigt);
    }, []);

    if (document.documentElement.offsetWidth < 480) {
        let heigtOptions = (height / 2) * 0.9;
        style = 'translate(-50%, -' + heigtOptions + 'px)';
        // style = 'translate(-50%, -50%)';

    } else {
        style = 'translate(-50%, -50%)';
    }

    if (window.performance) {
        if (reload == 1) {
            dispatch(closeModal());
        }
    }


    return (
        <>
            <Backdrop show={modal.isOpen}/>
            <div
                className="Modal"
                style={{
                    transform: modal.isOpen ? style : 'translate(-50%,-250vh)',
                    opacity: modal.isOpen ? '1' : '0',
                }}
            >
                <div className="summary-login summary-forgotPassword summary-addDays">
                    {!disableClose && (
                        <img src={CloseModal} className="closeModal" onClick={() => dispatch(closeModal())} alt=""/>
                    )}
                    {modal.component}
                </div>
            </div>
        </>
    );
};

export default Modal;