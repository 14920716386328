import LoanAction from "store/loan/LoanAction";
import {AppControllerState} from "store/appControler/AppControllerReducer";
import {LoanReducerState} from "store/loan/LoanReducer";
import AppControllerAction from "store/appControler/AppControllerAction";
import {AppStatus} from "types/enums/AppStatus";
import {AppCurrentStep} from "types/enums/AppCurrentStep";
import {getCurrentStep} from "helpers/utils";
import {SessionControllerState} from "store/sessionController/SessionControllerReducer";
import SessionControllerAction from "store/sessionController/SessionControllerAction";
import LinkTo from "helpers/LinkTo";
import {generateCookieId, getCookieCreatedDate} from "shared/utility";
import {Cookies_status} from "types/enums/Cookies_status";


export const IS_DEV = document.location.port?.includes("30") || document.location.origin?.includes("doc.endevel") || false;
// console.log("IS_DEV: ", IS_DEV);
// console.log(document.location)
export const IS_PROD = !IS_DEV;
export const ROOT_STORAGE_DOMAIN = process.env.REACT_APP_ROOT_API_URL;
export const CLIENT_URL = process.env.REACT_APP_CLIENT_URL
export const FILE_URL = process.env.REACT_APP_FILE_URL;

// export const FOXENTRY_API_KEY = "nPWvPMeCZWAFC1RpF6B0";
// export const FOXENTRY_API_DOMAIN = "https://production.api.foxentry.com";


/**
 * @function setup default app configuration
 *
 * @param controller - app controller - typeof AppControllerState
 * @param dispatch - useDispatch(thunk dispatcher)
 */
export const setAppConfig = (controller: AppControllerState, dispatch) => {
    if (!controller) {
        dispatch(AppControllerAction.setAppStatus(AppStatus.Started));
        dispatch(AppControllerAction.setCurrentStep(AppCurrentStep.Start));
    }

    if (!controller.cookies) {
        dispatch(AppControllerAction.setCookies({
            tech_cookies: true,
            analytics_cookies: false,
            advert_cookies: false,
            cookie_id: generateCookieId(),
            created_at: getCookieCreatedDate(),
            status: Cookies_status.NULL
        }));
    }
    if (!controller.total_steps) {
        dispatch(AppControllerAction.setTotalSteps(9));
    }

    if (!controller.attempt) {
        dispatch(AppControllerAction.setAttempt({name: "max_attempts", value: 3}));
        dispatch(AppControllerAction.setAttempt({name: "attempt", value: 0}));
    }

}

/**
 * @function setup loan configuration, init all states and default (starter) configuration
 *
 * @param controller - loan controller - typeof LoanReducerState
 * @param dispatch - useDispatch(thunk dispatcher)
 */
export const setLoanConfig = (controller: LoanReducerState, dispatch) => {
    if (!controller.priceList) {
        dispatch(LoanAction.fetchPriceList("fix"));
    }
    if (!controller.loan) {
        dispatch(LoanAction.setLoanConfig({
            base_price: 3000,
            base_days_amount: 35
        }));
        dispatch(LoanAction.setLoanSelection({
            selected_price: 3000,
            selected_days: 35,
            loan_price: 0,
            deferral_fee: 0,
            total_loan_price: 3000
        }))
        dispatch(LoanAction.setLoanPurpose({
            name: 'purpose',
            value: null
        }));
        dispatch(LoanAction.setLoanPurpose({
            name: 'purpose_text',
            value: null
        }));
        dispatch(LoanAction.setLoanPurpose({
            name: 'applied_elsewhere',
            value: null
        }));
        dispatch(LoanAction.setLoanPurpose({
            name: 'applied_elsewhere_text',
            value: null
        }));
        dispatch(LoanAction.setLoanPurpose({
            name: 'recurring_debt',
            value: null
        }));
    }

    if (!controller.sendRequest) {
        dispatch(LoanAction.setAgreement({name: 'personalData', value: null}))
        dispatch(LoanAction.setAgreement({name: 'personalDataMarketing', value: null}))
        dispatch(LoanAction.setAgreement({name: 'repi', value: null}))
    }
}

/**
 * @function - setup on start session state
 *
 * @param controller - session controller - type of SessionReducerState
 * @param dispatch - useDispatch(thunk dispatcher)
 */

export const setSessionConfig = (controller: SessionControllerState, dispatch) => {
    if (!controller.spendingReport) {
        dispatch(SessionControllerAction.setUserSpendingReport({name: "income", value: null}))
        dispatch(SessionControllerAction.setUserSpendingReport({name: "expenses", value: null}))
        dispatch(SessionControllerAction.setUserSpendingReport({name: "loan_payments", value: null}))
        dispatch(SessionControllerAction.setUserSpendingReport({name: "other_income", value: null}))
    }

    if (!controller.mailingAddress) {
        dispatch(SessionControllerAction.setUserMailingAddress({name: "has_mailing_address" , value: null}))
        dispatch(SessionControllerAction.setUserMailingAddress({name: "city" , value: null}))
        dispatch(SessionControllerAction.setUserMailingAddress({name: "house_number" , value: null}))
        dispatch(SessionControllerAction.setUserMailingAddress({name: "street" , value: null}))
        dispatch(SessionControllerAction.setUserMailingAddress({name: "postal_code" , value: null}))
        dispatch(SessionControllerAction.setUserMailingAddress({name: "address" , value: null}))
    }

    if (!controller.userAccount) {
        dispatch(SessionControllerAction.setUserAccount({name: 'email', value: null}))
        dispatch(SessionControllerAction.setUserAccount({name: 'phone_number', value: null}))
        dispatch(SessionControllerAction.setUserAccount({name: 'password', value: null}))
        dispatch(SessionControllerAction.setUserAccount({name: 'password_repeat', value: null}))
    }

    if (!controller.lustrations) {
        dispatch(SessionControllerAction.setLustrations({
            internal: null,
            blacklist: null,
            insolvency: null,
            address: null
        }))
    }
}

/**
 * @function setup reset APP state correctly, when you go back in history or when
 * application goes to fail due to incorrect answers
 *
 * @param controller - app controller - typeof AppControllerState
 * @param history - history from package react-router
 * @param location - useLocation()
 * @param dispatch - useDispatch(thunk dispatcher)
 */
export const resetAppState = (appController: AppControllerState, loanController: LoanReducerState, sessionController: SessionControllerState, history, location, dispatch) => {
    if (window.performance) {
        if (performance.navigation.type === 1 && location.pathname === LinkTo.home()) {
            dispatch(AppControllerAction.setAppStatus(AppStatus.Started));
        } else {
            // Reset AppState
                dispatch(AppControllerAction.setAppStatus(AppStatus.Started));
                // dispatch(AppControllerAction.setCurrentStep(AppCurrentStep.Start));
                dispatch(AppControllerAction.setAppProgressStatus(null));
                dispatch(AppControllerAction.setCurrentStep(AppCurrentStep.Start));
                dispatch(AppControllerAction.setTotalSteps(9));
                dispatch(AppControllerAction.setAttempt({name: "max_attempts", value: 3}));
                dispatch(AppControllerAction.setAttempt({name: "attempt", value: 0}));
                dispatch(AppControllerAction.setEmailResponse(null))

            // Reset LoanState
                dispatch(LoanAction.fetchPriceList("fix"));
                dispatch(LoanAction.setLoanConfig({
                    base_price: 3000,
                    base_days_amount: 35
                }));
                dispatch(LoanAction.setLoanSelection({
                    selected_price: 3000,
                    selected_days: 35,
                    loan_price: 0,
                    deferral_fee: 0,
                    total_loan_price: 3000
                }))
                dispatch(LoanAction.setLoanPurpose({
                    name: 'purpose',
                    value: null
                }));
                dispatch(LoanAction.setLoanPurpose({
                    name: 'purpose_text',
                    value: null
                }));
                dispatch(LoanAction.setLoanPurpose({
                    name: 'applied_elsewhere',
                    value: null
                }));
                dispatch(LoanAction.setLoanPurpose({
                    name: 'applied_elsewhere_text',
                    value: null
                }));
                dispatch(LoanAction.setLoanPurpose({
                    name: 'recurring_debt',
                    value: null
                }));
                dispatch(LoanAction.setAgreement({name: 'personalData', value: null}))
                dispatch(LoanAction.setAgreement({name: 'personalDataMarketing', value: null}))
                dispatch(LoanAction.setAgreement({name: 'repi', value: null}))

            // Reset SessionState
                dispatch(SessionControllerAction.setUserSpendingReport({name: "income", value: null}))
                dispatch(SessionControllerAction.setUserSpendingReport({name: "expenses", value: null}))
                dispatch(SessionControllerAction.setUserSpendingReport({name: "loan_payments", value: null}))
                dispatch(SessionControllerAction.setUserSpendingReport({name: "other_income", value: null}))

                dispatch(SessionControllerAction.setUserMailingAddress({name: "has_mailing_address" , value: null}))
                dispatch(SessionControllerAction.setUserMailingAddress({name: "city" , value: null}))
                dispatch(SessionControllerAction.setUserMailingAddress({name: "house_number" , value: null}))
                dispatch(SessionControllerAction.setUserMailingAddress({name: "street" , value: null}))
                dispatch(SessionControllerAction.setUserMailingAddress({name: "postal_code" , value: null}))
                dispatch(SessionControllerAction.setUserMailingAddress({name: "address" , value: null}))

                dispatch(SessionControllerAction.setUserAccount({name: 'email', value: null}))
                dispatch(SessionControllerAction.setUserAccount({name: 'phone_number', value: null}))
                dispatch(SessionControllerAction.setUserAccount({name: 'password', value: null}))
                dispatch(SessionControllerAction.setUserAccount({name: 'password_repeat', value: null}))

                dispatch(SessionControllerAction.setUser(null));
                dispatch(SessionControllerAction.setUserQuestion({name: 'has_execution', value: null}));
                dispatch(SessionControllerAction.setUserQuestion({name: 'has_office_address', value: null}));
                dispatch(SessionControllerAction.setUserQuestion({name: 'has_insolvency', value: null}));

                dispatch(SessionControllerAction.setLustrations({
                    internal: undefined,
                    blacklist: undefined,
                    insolvency: undefined,
                    address: undefined
                }))
            // console.log("_____reset____");
            if (location.pathname === LinkTo.contact()) {
                dispatch(AppControllerAction.setAppStatus(AppStatus.Contact))
            }
        }
    }

    if ((appController.app_status === AppStatus.Started || appController.app_status === null || appController.app_status === AppStatus.Error) && location.pathname !== LinkTo.home()) {
        // console.log('____empty local storage____')
        if (location.pathname === LinkTo.contact()) {
            history.push(LinkTo.contact());
        } else {
            history.push(LinkTo.home());
        }
    } else {
        if (appController.current_step !== AppCurrentStep.VerificationQuestionsFail) {
            const currentStep = getCurrentStep(location.pathname);
            dispatch(AppControllerAction.setCurrentStep(currentStep));
        }
        // const currentStep = getCurrentStep(location.pathname);
        // dispatch(AppControllerAction.setCurrentStep(currentStep));
        // console.log('____local storage____')
    }
}
