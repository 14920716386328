import React, {Suspense, useEffect} from 'react';
import {Redirect, Route} from "react-router-dom";
import AOS from 'aos';
import "aos/dist/aos.css";
import 'App.scss';
import Background from './assets/UserAssets/pattern.svg';
import Layout from 'Layout/Layout';
import Contact from "pages/contact/Contact";
import {useDispatch} from "useDispatch";
import SlideRoutes from 'react-slide-routes';
import Home from "pages/home/Home";
import Calculation from "pages/calculation/Calculation";
import {useTypedSelector} from "helpers/reducers";
import {RouteComponentProps, useLocation, withRouter} from "react-router";
import Verification from "pages/verification/Verification";
import CalculationSummary from "pages/calculation/components/CalculationSummary";
import VerificationSummary from "pages/verification/components/VerificationSummary";
import VerificationQuestions from "pages/verification/components/VerificationQuestions";
import Purpose from "pages/purpose/Purpose";
import PurposeRepeat from "pages/purpose/components/PurposeRepeat";
import SpendingReport from "pages/purpose/components/SpendingReport";
import Address from "pages/address/Address";
import Registration from "pages/registration/Registration";
import SendRequest from "pages/sendRequest/SendRequest";
import Loader from "components/Loader";
import ModalAction from "store/modal/ModalAction";
import VerificationQuestionsFail from "pages/verification/components/VerificationQuestionsFail";
import {resetAppState, setAppConfig, setLoanConfig, setSessionConfig} from "helpers/conifg";
import Finish from "pages/finish/Finish";
import {getCurrentStep} from "helpers/utils";
import AppControllerAction from "store/appControler/AppControllerAction";
import Denied from "components/Denied";
import Cookies from 'js-cookie';
import {AppCurrentStep} from "types/enums/AppCurrentStep";

interface Params {
    a_id: string,
    t_id: string,
}

interface Props extends RouteComponentProps<Params> {}


interface CookieOptions {
    expires?: number | Date;
    path?: string;
    domain?: string;
    secure?: boolean;
    sameSite?: 'strict' | 'lax' | 'none';
}

const setCookie = (name: string, value: string, options?: CookieOptions) => {
    Cookies.set(name, value, options);
};

const App = (props: Props) => {
    const dispatch = useDispatch();
    const appController = useTypedSelector(state => state.appController);
    const loanController = useTypedSelector(state => state.loanController);
    const sessionController = useTypedSelector(state => state.sessionController);
    const modal = useTypedSelector(state => state.modal.modalView);
    const location = useLocation();


    useEffect(() => {
        setAppConfig(appController, dispatch);
        setLoanConfig(loanController, dispatch);
        setSessionConfig(sessionController, dispatch);

        if (!modal) {
            dispatch(ModalAction.initModal());
        }

        AOS.init({
            duration: 400,
            disable: 'phone',
            once: true,
            offset: 0
        });
        AOS.refresh();

    }, []);

    useEffect(() => {
        if (location.pathname === '/') {
            resetAppState(appController, loanController, sessionController, props.history, location, dispatch);
        }

        const currentStep = getCurrentStep(location.pathname);
        if (location.pathname === '/zamitnuto') {
            // dispatch(AppControllerAction.setAppStatus(AppStatus.Error));
            dispatch(AppControllerAction.setCurrentStep(AppCurrentStep.VerificationQuestionsFail));

        } else {
            dispatch(AppControllerAction.setCurrentStep(currentStep));
        }

    }, [location.pathname]);


    const processCookie = (cookieName: string) => {
        const cookie = Cookies.get(cookieName)

        return cookie ? JSON.parse(cookie) : undefined
    }


    // handle @espoluprace
    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const a_aid = queryParams.get("a_aid");
        const t_id = queryParams.get("t_id");
        const cid = queryParams.get("cid");

        if (a_aid !== null && t_id !== null) {
            if (t_id === 'admitad') {
                dispatch(AppControllerAction.setEspoluprace({ // new state admitad
                    a_aid,
                    t_id,
                    origin: 17,
                }))
                setCookie('admitad_uid', JSON.stringify({
                    a_aid,
                    t_id,
                    created_at: new Date().toISOString()
                }), {expires: 30})
            } else {
                dispatch(AppControllerAction.setEspoluprace({ //espoluprace
                    a_aid,
                    t_id,
                    origin: 13,
                }))
                setCookie('espoluprace_uid', JSON.stringify({
                    a_aid,
                    t_id,
                    created_at: new Date().toISOString()
                }), {expires: 7})
            }
        }

        if (cid !== null) {
            dispatch(AppControllerAction.setLeadGenia({
                cid: cid,
                origin: 18,
            }))
            setCookie('leadgenia_uid', JSON.stringify({
                cid: cid,
                created_at: new Date().toISOString()
            }), {expires: 30})
        }

        const admitadCookie = processCookie('admitad_uid')
        const espolupraceCookie = processCookie('espoluprace_uid')
        const leadGeniaCookie = processCookie('leadgenia_uid')

        if (admitadCookie && espolupraceCookie) {
            if (admitadCookie.created_at < espolupraceCookie.created_at) {
                dispatch(AppControllerAction.setEspoluprace({ // new state admitad
                    a_aid: admitadCookie.a_aid,
                    t_id: admitadCookie.t_id,
                    origin: 17,
                }))
            } else {
                dispatch(AppControllerAction.setEspoluprace({ //espoluprace
                    a_aid: espolupraceCookie.a_aid,
                    t_id: espolupraceCookie.t_id,
                    origin: 13,
                }))
            }
        }

        if (admitadCookie) {
            dispatch(AppControllerAction.setEspoluprace({ // new state admitad
                a_aid: admitadCookie.a_aid,
                t_id: admitadCookie.t_id,
                origin: 17,
            }))
        }

        if (espolupraceCookie) {
            dispatch(AppControllerAction.setEspoluprace({ //espoluprace
                a_aid: espolupraceCookie.a_aid,
                t_id: espolupraceCookie.t_id,
                origin: 13,
            }))
        }

        if (leadGeniaCookie) {
            dispatch(AppControllerAction.setLeadGenia({
                cid: leadGeniaCookie.cid,
                origin: 18,
            }))
        }
    }, []);


    if(!loanController.loan) {
        return <Loader/>
    }


    return (
    <div className={"App"}>
        <div className="App-background">
            <img src={Background} alt="Pattern Background"/>
        </div>
        <Layout>
            <Suspense fallback={<Loader/>}>
                    {/*//@ts-ignore*/}
                    <SlideRoutes location={location} duration={650}>
                        <Route path={"/zadost/vyber"} component={Calculation}/>
                        <Route path={"/zadost/udaje"} component={Verification}/>
                        <Route path={"/zadost/rejstrik"} component={VerificationSummary}/>
                        <Route path={"/zadost/shrnuti"} component={CalculationSummary}/>
                        <Route path={"/zadost/otazky"} component={VerificationQuestions}/>
                        <Route path={"/zadost/zamitnuto"} component={VerificationQuestionsFail}/>
                        <Route path={"/ucel-poskytnuti/opakovany-uver"} component={PurposeRepeat}/>
                        <Route path={"/ucel-poskytnuti/ucel"} component={Purpose}/>
                        <Route path={"/ucel-poskytnuti/finance"} component={SpendingReport}/>
                        <Route path={"/doplnujici-udaje/adresa"} component={Address}/>
                        <Route path={"/doplnujici-udaje/kontakt"} component={Registration}/>
                        <Route path={"/odeslani-zadosti/souhlasy"} component={SendRequest}/>
                        <Route path={"/zamitnuto"} component={Denied}/>
                        <Route path={"/konec"} component={Finish}/>
                        <Route path="/kontakt" component={Contact}/>
                        <Route path="/" component={Home} exact/>
                        <Redirect to="/" />
                    </SlideRoutes>
            </Suspense>
        </Layout>
    </div>
  );
};

export default withRouter(App);
