import React from 'react';
import Button from "components/Button";
import Loader from "components/Loader";
import {closeModal} from "store/action";
import {useDispatch} from "useDispatch";

const ModalPriceList = props => {
    const dispatch = useDispatch()

    const modalHandler = () => {
        dispatch(closeModal());
    }

    return (
        <div className="VerificationErrorModal">
            <Loader/>
            <h3 className="Modal-heading">Je nám líto, ale momentálně nemůžeme načíst ceník z důvodu údržby, prosím zkuste to později.</h3>
            <Button class={"btn btn-purple Modal-btn mt-5 mx-auto"} onClick={modalHandler}>Nashledanou</Button>
        </div>
    );
};

export default ModalPriceList;