import React, { useState} from 'react';
import axios, {AxiosInstance} from "axios";
import _ from "lodash";

const useFoxentryAddressWhisper = (inputValue?: string, type?: 'lookup' | 'whisper', url?: string, options?: any) => {
    const [response, setResponse] = useState<any>(null);
    const [showWhisperer, setShowWhisperer] = useState<boolean>(false);

    const foxentryAxiosInstance = (): AxiosInstance => {
        const foxentry = axios.create({
            baseURL: process.env.REACT_APP_FOXENTRY_API_DOMAIN
        });

        return foxentry;
    }

    const foxentry = foxentryAxiosInstance();

    const validateEmail = async (query: string) => {
        try {
            const response = await foxentry.post('/email/validate', {
                apiKey: process.env.REACT_APP_FOXENTRY_API_KEY,
                request: {
                    query: {email: query},
                    options: {
                        validationType: 'extended',
                        acceptDisposableEmails: true,
                        acceptFreemails: true
                    }
                }
            })

            const {isValid, flags: {isCatchAllServer, isDisposableEmailAddress, isFreemail}} = response.data.response.result

            return isValid
        } catch(error) {
            console.log(error)
        }
    }


    const validatePhone = async (query: string) => {
        try {
            const response = await foxentry.post('/phone/validate', {
                apiKey: process.env.REACT_APP_FOXENTRY_API_KEY,
                request: {
                    query: {number: query, prefix: '+420'},
                    options: {validationType: 'basic', formatNumber: false, }
                }
            })
            return response.data.response.result.isValid
        } catch(error) {
            console.log(error)
        }
    }

    const validateName = async (query: {key: string, value?: string}) => {
        try {
            const response = await foxentry.post('/name/validate', {
                apiKey: process.env.REACT_APP_FOXENTRY_API_KEY,
                request: {
                    query: {[query.key]: query.value},
                    options: {
                        dataScope: 'basic',
                        dataSource: ['CZ'],
                        acceptDegrees: true,
                        acceptContext: true,
                        validationDepth: 'minimal',
                        smartMode: false
                    }
                }
            })
            setResponse({type: query.key, results: response.data.response.suggestions.map(suggesstion => suggesstion.data)})
            setShowWhisperer(true)
        } catch(error) {
            console.log(error)
        }

    }

    const fetchAddress = (url: string = '/location/search', query: any, options = {dataScope: "basic"}): void => {
        foxentry.post(url, {
            apiKey: process.env.REACT_APP_FOXENTRY_API_KEY,
            request: {
                query: {
                    type: "streetWithNumber",
                    value: query,
                    filter: {country: 'CZ'}
                },
                options: {
                    ...options
                }
            }
        }).then((r: any) => {
            if (r.data.response) {
                setResponse(r.data.response.results.map(item => item.data));
                setShowWhisperer(true);
            }
        }).catch(err => {
            console.log(err)
            setShowWhisperer(false);
        })
    }

    function debounceEventHandler(...args) {
        // @ts-ignore
        const debounced = _.debounce(...args)
        return function(e) {
            e.persist()
            return debounced(e)
        }
    }

    return {
        response,
        showWhisperer,
        setShowWhisperer,
        debounceEventHandler,
        fetchAddress,
        validateName,
        validatePhone,
        validateEmail
    }
}

export default useFoxentryAddressWhisper;