import React, {useEffect, useRef, useState} from 'react';
import clsx from "clsx";
import {useDispatch} from "useDispatch";
import LoanAction from "store/loan/LoanAction";

interface PurposeItemProps {
    name: string,
    value: string,
    label: string,
    activeValue: string,
    objectItem: string,
    setActiveValue: (value) => void,
    onClick?: (event) => void
}

const PurposeItem = (props: PurposeItemProps) => {
    const [checked, setChecked] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        if (props.activeValue === props.value) {
            setChecked(true);
        } else {
            setChecked(false)
        }
    }, [props.activeValue]);

    const onCheckedHandler = (e) => {
        props.setActiveValue(e.target.value);
        dispatch(LoanAction.setLoanPurpose({
            name: props.objectItem,
            value: e.target.value}))
    }

    return (
        <label
            className={clsx('Purpose-btn', checked && "Purpose-btn__active")}>
            <input
                type="radio"
                name={props.name}
                value={props.value}
                checked={checked}
                onClick={props.onClick || onCheckedHandler}
            />
            <span className="labelText">{props.label}</span>
        </label>
    );
};

export default PurposeItem;