export enum AppCurrentStep {
    Start = 'start',
    Calculation = "calculation",
    Verification = "verification",
    VerificationSummary = "verification_summary",
    CalculationSummary = "calculation_summary",
    VerificationQuestions = "verification_questions",
    VerificationQuestionsFail = "verification_question_fail",
    Purpose = "purpose",
    PurposeRepeat = "purpose_repeat",
    SpendingReport = "spending_report",
    Address = "address",
    RegisterAccount = 'register_account',
    SendRequest = "send_request",
    Finish = "finish",
    Contact = "contact",
    Null = " null"
}