import React from "react";

import './Servis.scss';
import Phone from '../../../assets/UserAssets/phone.svg';
import Aux from "../../../hoc/Auxiliary";

const servis = () => {
    return (
        <Aux>
            <div className="Servis d-flex">
                <div className="Servis-icon">
                    <img src={Phone} alt="Phone icon"/>
                </div>
                <div className="Servis-number">
                    <div className="Servis-title">Zákaznický servis</div>
                    <a href="tel:+420587407707">+420 587 407 707</a>
                </div>
            </div>
        </Aux>
    );
};

export default servis;