import SessionControllerAction from "store/sessionController/SessionControllerAction";
import {EmailExistsResponse, UserExistsResponse, UserResponse} from "types/responses";
import update from "immutability-helper";
import {
    MailingAddress,
    QuestionAnswer,
    SpendingReport,
    TerminateStatusObject,
    UserAccount,
    UserAccountItem,
    VerificationQuestionsEntity
} from "types/entities";
import {SpendingReportItem} from "types/entities/SpendingReport";
import {MailingAddressItem} from "types/entities/MailingAddress";

interface SessionControllerReducerSetUserAction {
    payload: UserResponse,
    type: typeof SessionControllerAction.SET_USER
}

interface SessionControllerReducerSetUserExistsAction {
    payload: UserExistsResponse,
    type: typeof SessionControllerAction.SET_USER_EXISTS
}

interface SessionControllerReducerSetUserQuestionAction {
    payload: QuestionAnswer,
    type: typeof SessionControllerAction.SET_USER_QUESTION
}

interface SessionControllerReducerResetSessionDataAction {
    payload: any,
    type: typeof SessionControllerAction.RESET_SESSION_DATA
}

interface SessionControllerReducerSetBirthNumberAction {
    payload: string,
    type: typeof SessionControllerAction.SET_BIRTH_NUMBER
}

interface SessionControllerReducerSetQuestionTerminateStatusAction {
    payload: TerminateStatusObject,
    type: typeof SessionControllerAction.SET_QUESTION_TERMINATE_STATUS
}

interface SessionControllerReducerSetSpendingReportAction {
    payload: SpendingReportItem,
    type: typeof SessionControllerAction.SET_USER_SPENDING_REPORT
}

interface SessionControllerReducerSetUserMailingAddressAction {
    payload: MailingAddressItem,
    type: typeof SessionControllerAction.SET_USER_MAILING_ADDRESS
}

interface SessionControllerReducerSetUserEmailExistsAction {
    payload: EmailExistsResponse,
    type: typeof SessionControllerAction.SET_USER_EMAIL_EXISTS
}

interface SessionControllerReducerSetUserAccountAction {
    payload: UserAccountItem,
    type: typeof SessionControllerAction.SET_USER_ACCOUNT
}

interface SessionControllerReducerSetLustrationsAction {
    payload: any,
    type: typeof SessionControllerAction.SET_LUSTRATIONS
}

interface SessionControllerReducerSetGaIdAction {
    payload: any,
    type: typeof SessionControllerAction.SET_GA_ID
}

type SessionControllerReducerAction =
    SessionControllerReducerSetUserAction
    | SessionControllerReducerSetUserExistsAction
    | SessionControllerReducerSetUserQuestionAction
    | SessionControllerReducerResetSessionDataAction
    | SessionControllerReducerSetBirthNumberAction
    | SessionControllerReducerSetQuestionTerminateStatusAction
    | SessionControllerReducerSetSpendingReportAction
    | SessionControllerReducerSetUserMailingAddressAction
    | SessionControllerReducerSetUserEmailExistsAction
    | SessionControllerReducerSetUserAccountAction
    | SessionControllerReducerSetLustrationsAction
    | SessionControllerReducerSetGaIdAction;

export interface SessionControllerState {
    user: UserResponse,
    userExists: UserExistsResponse,
    verificationQuestions: VerificationQuestionsEntity,
    spendingReport: SpendingReport,
    mailingAddress: MailingAddress,
    verifyUserEmail: EmailExistsResponse,
    userAccount: UserAccount,
    lustrations: any,
    gaId: string | null
}

class SessionControllerReducer {
    initialState: SessionControllerState = {
        user: null,
        userExists: null,
        verificationQuestions: null,
        spendingReport: null,
        mailingAddress: null,
        verifyUserEmail: null,
        userAccount: null,
        lustrations: null,
        gaId: null
    }

    reducer = (state: SessionControllerState = this.initialState, action: SessionControllerReducerAction): SessionControllerState => {
        switch (action.type) {
            case SessionControllerAction.SET_USER:
                return update(state, {
                    user: {
                        $set: action.payload
                    }
                })
            case SessionControllerAction.SET_USER_EXISTS:
                return update(state, {
                    userExists: {
                       $set: action.payload
                    }
                })

            case SessionControllerAction.SET_USER_QUESTION:
                return {
                    ...state,
                    verificationQuestions: {
                        ...state.verificationQuestions,
                        [action.payload.name]: action.payload.answer
                    }
                }

            case SessionControllerAction.RESET_SESSION_DATA:
                return {
                    ...state,
                    user: null,
                    userExists: null,
                    verificationQuestions: null
                }

            case SessionControllerAction.SET_BIRTH_NUMBER:
                return {
                    ...state,
                    user: {
                        ...state.user,
                        birth_number: action.payload
                    }
                }

            case SessionControllerAction.SET_QUESTION_TERMINATE_STATUS:
                return {
                    ...state,
                    verificationQuestions: {
                        ...state.verificationQuestions,
                        terminate: {
                            ...state.verificationQuestions.terminate,
                            [action.payload.question]: action.payload.status
                        }
                    }
                }

            case SessionControllerAction.SET_USER_SPENDING_REPORT:
                return {
                    ...state,
                    spendingReport: {
                        ...state.spendingReport,
                        [action.payload.name]: action.payload.value
                    }
                }

            case SessionControllerAction.SET_USER_MAILING_ADDRESS:
                return {
                    ...state,
                    mailingAddress: {
                        ...state.mailingAddress,
                        [action.payload.name]: action.payload.value
                    }
                }

            case SessionControllerAction.SET_USER_ACCOUNT:
                return {
                    ...state,
                    userAccount: {
                        ...state.userAccount,
                        [action.payload.name]: action.payload.value
                    }
                }

            case SessionControllerAction.SET_USER_EMAIL_EXISTS:
                return update(state, {
                    verifyUserEmail: {
                        $set: action.payload
                    }
                });

            case SessionControllerAction.SET_LUSTRATIONS:
                const nonNullObject = Object.keys(action.payload).filter(item => item !== null)
                console.log(nonNullObject)
                console.log(action.payload)
                // return {
                //     ...state,
                //     lustrations: {
                //         ...state.lustrations,
                //         ...nonNullObject
                //     }
                // }

               return update(state, {
                    lustrations: {
                        $set: action.payload
                    }
                });

            case SessionControllerAction.SET_GA_ID:
                return update(state, {
                    gaId: {
                        $set: action.payload
                    }
                })

            default:
                return state;
        }
    }
}

export default SessionControllerReducer