import React, {useEffect} from 'react';
import Button from "components/Button";
import {useDispatch} from "useDispatch";
import {openModal} from "store/action";
import CookiesModal from "components/Cookies/components/CookiesModal";
import CookiesImg from "assets/UserAssets/cookies.svg";
import useWindowDimensions from "helpers/useWindowDimensions";

interface Props {
    agreeCookies: () => void
    disAgreeCookies: () => void
}

const Cookies = (props: Props) => {
    const dispatch = useDispatch();
    const {width, height} = useWindowDimensions()
    const openCookiesModal = () => dispatch(openModal(<CookiesModal/>, {}));

    return (
        <div className="Cookies" data-aos={width <= 992 ? "" : "fade-up"}
             data-aos-duration="500"
             data-aos-delay="1000">
            <div className="d-flex flex-wrap justify-content-center">
                <img src={CookiesImg} alt="cookies icon"/>
                <h1>Získejte od nás porci jen toho, co potřebujete !</h1>
            </div>
            <p className={"mt-3 mb-4"}>Webová aplikace <span className={"decline-link"}>VistaCredit.cz</span> <b> používá cookies a další technologie k tomu,
                aby zajistila vaší bezpečnost a svou vlastní spolehlivost,
                sledovala svou výkonnost a zprostředkovala personalizované služby</b>. Za tímto účelem shromažďujeme informace o uživatelích,
                jejich chování a zařízeních. Kliknutím na klávesu <b>“OK”</b>, souhlasíte
                s tím, že si tyto informace uložíme a případně je budeme sdílet se třetími stranami,
                např. našimi obchodními partnery. Pokud toto odmítnete, budeme používat jen základní cookies.
                Pro podrobnosti a nastavení vlastních úprav zvolte možnost <b>“Upravit nastavení“</b>.
                Své preference můžete kdykoliv upravit tím, že zmažete cookies ve svém prohlížeči. Více informací naleznete v
                našich <span className={"decline-link"}>GDPR informace ke zpracování osobních údajů</span>.</p>
            <div className="d-flex justify-content-center">
                <Button class={"btn btn-main btn-main-cookies"} onClick={openCookiesModal}>Upravit nastavení</Button>
                <Button class={"btn btn-purple btn-purple-cookies reset-top-0"} onClick={props.agreeCookies}>OK</Button>
            </div>
            <p className={"mt-4"}>Souhlas můžete odmítnout <span className={"decline-link"} onClick={props.disAgreeCookies}>zde</span>.</p>
        </div>
    );
};

export default Cookies;