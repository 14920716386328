import React from 'react';
import BlueArrow from "assets/UserAssets/blueArrow.svg";

interface Props {
    title: any,
    register: any,
    onChange: (question, value) => void
}

const SpendingReportArrowInput = (props: Props) => {
    return (
        <li className="calcSummary-listItem d-flex justify-content-between Question-listItem SpendingReport-listItem">
            <div className="spendQuestions-wrap">
                <h2 className={"text-left"}>{props.title}</h2>
                <p className="billsQuestion-wrap desktopBillsQuestion-wrap">
                    <img src={BlueArrow} className="blueArrow" alt=""/>
                    <p className="billsQuestion">Kolik z výdajů činí <b>splátky?</b></p>
                </p>
            </div>
            <div className="spend-wrap">
                <div className="currencyWrap">
                    <input
                        {...props.register('expenses')}
                        type="text"
                        name="expenses"
                        onChange={(e) => props.onChange('expenses', e.target.value)}
                    />
                    <span className="currencyPlaceholder">Kč</span>
                </div>
                <div className="mobileBills-wrap">
                    <p className="billsQuestion-wrap">
                        <img src={BlueArrow} className="blueArrow" alt=""/>
                        <p className="billsQuestion">Kolik z výdajů činí <b>splátky?</b></p>
                    </p>
                    <div className="currencyWrap bills">
                        <input
                            {...props.register('loan_payments')}
                            type="text"
                            name="loan_payments"
                            onChange={(e) => props.onChange('loan_payments', e.target.value)}
                        />
                        <span className="currencyPlaceholder">Kč</span>
                    </div>
                </div>
            </div>

        </li>
    );
};

export default SpendingReportArrowInput;