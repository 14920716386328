import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import Input from '@material-ui/core/Input';


interface CalculationSliderProps {
    min: number,
    max: number,
    step: number,
    marks: Array<any>,
    defaultValue: number | number[],
    setSliderValue:  React.Dispatch<React.SetStateAction<number | number[]>>
    days?: boolean
    type?: "fix" | "rent" | "deferral"
}

const useStyles = makeStyles({
    root: {
        width: "100%",
    },
    input: {
        width: 42,

    },
});

const CalculationSlider = (props: CalculationSliderProps) => {
    const classes = useStyles();
    const [value, setValue] = React.useState<number | number[]>(props.defaultValue);
    
    const roundSliderValue = (value: number, type?: boolean) => {
        if (!type) {
            return Math.floor(value / 1000) * 1000
        }
        
        return Math.floor(value / 7.0) * 7
    }
    
    const handleSliderChange = (event: any, newValue: number | number[]) => {
        const roundedValue = roundSliderValue(+newValue, props.type !== undefined)
        
        if (props.days && props.type === "fix") {
            if (newValue < 35 && newValue > 14) {
                setValue(newValue);
                return
            }
        }
        
        setValue(newValue);
        props.setSliderValue(roundedValue);
    };

    // const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     setValue(event.target.value === '' ? '' : Number(event.target.value));
    // };

    const handleBlur = () => {
        if (value < 0) {
            setValue(0);
        } else if (value > 100) {
            setValue(100);
        }
    };

    const marks = [
        {
            value: 5000,
        },
        {
            value: 7500,
        },
        {
            value: 10000,
        },
        {
            value: 12500,
        },
        {
            value: 15000,
        },
        {
            value: 17500,
        },
        {
            value: 20000,
        },
        {
            value: 23000,
        },
    ];
    
    // const step = props.days ? value < 35 ? 35 : 7 : props.step
    
    return (
        <div className={classes.root}>
            <Slider
                value={typeof value === 'number' ? value : 0}
                onChange={handleSliderChange}
                min={props.min}
                step={1}
                max={props.max}
                defaultValue={props.defaultValue}
                aria-labelledby="input-slider"
                marks={props.marks}
            />

            <div className="d-flex justify-content-between">
                <Typography id="input-slider" gutterBottom>
                    {props.min}
                </Typography>
                <Typography id="input-slider" gutterBottom>
                    {props.max}
                </Typography>
            </div>
        </div>
    );
}

export default CalculationSlider;

