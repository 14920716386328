import React from 'react';
import Button from "components/Button";
import {useDispatch} from "useDispatch";
import {closeModal} from "store/action";

const VerificationErrorModal = props => {
    const dispatch = useDispatch();

    const verifyUserAgainHandler = () => {
        dispatch(closeModal());
    }

    return (
        <div className="VerificationErrorModal">
            <h3 className="Modal-heading">Je nám líto, ale pravděpodobně <b>nejste v
                databázi registru živnostenských oprávnění</b>, překontrolujte prosím zadání
                <b> Vašeho jména, příjmení a rodného čísla</b>.</h3>
            <p className="Modal-text">Pokud problém trvá, kontaktujte naši zákaznickou podporu:</p>
            <a href="tel:+420587407707" className="Modal-phone d-block"><b>+420 587 407 707</b></a>
            <p className="Modal-text mt-3">nebo e-mail:</p>
            <a href="mailto:admin@vistacredit.cz" className="Modal-email d-block">admin@vistacredit.cz</a>
            <Button class={"btn btn-purple Modal-btn mt-5 mx-auto"} onClick={verifyUserAgainHandler}>Znova zkontrolovat</Button>
        </div>
    );
};

export default VerificationErrorModal;