import ModalAction from "store/modal/ModalAction";

export function openModal(component, props?: any) {
    return dispatch => {
        return dispatch({
            type: ModalAction.SET_OPEN_MODAL,
            component,
            props,
            isOpen: true,
        });
    };
}

export function closeModal(force?: boolean) {
    return (dispatch, getState) => {
        const modal = getState().modal;
        if (!force && modal.props?.requireCloseConfirmation === true) {
            // // @todo pretty confirm
            // if (!confirm("Do you want to close the form?")) {
            //     return false;
            // }
            return false;
        }
        return dispatch({
            type: ModalAction.SET_OPEN_MODAL,
            component: null,
            isOpen: false
        });
    };
}