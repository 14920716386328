import React, {useEffect} from 'react';
import Verify from "assets/UserAssets/verify.svg";
import OptionCircles from "assets/UserAssets/optionCircles.svg";
import ViewContainer from "components/ViewContainer";
import ImageCol from "components/ImageCol";
import ContentCol from "components/ContentCol";
import {useTypedSelector} from "helpers/reducers";
import {SessionControllerState} from "store/sessionController/SessionControllerReducer";
import Loader from "components/Loader";
import {RouteComponentProps, withRouter} from "react-router";
import {getNextStep, getPrevStep} from "helpers/utils";
import {AppControllerState} from "store/appControler/AppControllerReducer";
import AppControllerAction from "store/appControler/AppControllerAction";
import {useDispatch} from "useDispatch";
import ActionButtons from "pages/verification/components/ActionButtons";
import UserExists from "pages/verification/components/UserExists";

interface Params {}

interface Props extends RouteComponentProps<Params> {}

const VerificationSummary = (props: Props) => {
    const sessionController: SessionControllerState = useTypedSelector(state => state.sessionController)
    const appController: AppControllerState = useTypedSelector(state => state.appController);
    const dispatch = useDispatch();

    if (!sessionController) {
        return <Loader/>
    }

    const onPrevStepHandler = () => {
        const prevStep = getPrevStep(appController.current_step);
        dispatch(AppControllerAction.setCurrentStep(prevStep.newStep));
        props.history.push(prevStep.page);
    }

    const onNextStepHandler = () => {
        const nextStep = getNextStep(appController.current_step);
        dispatch(AppControllerAction.setCurrentStep(nextStep.newStep))
        props.history.push(nextStep.page);
    }

    return (
        <ViewContainer class={"mt-3 mt-lg-0"}>
            <ImageCol lg={3} image={Verify}/>
            <ContentCol lg={6} class={"summary-textBox"}>
                <div className="summary-box">
                    <img src={OptionCircles} className="summary-optionsCircles" alt=""/>
                    <h1 className="summary-userName">{sessionController.user?.first_name + ' ' + sessionController.user?.last_name}</h1>
                    <p className="summary-greyText summary-ic">{'IČ ' + sessionController?.user?.company_number}</p>
                    <div className="summary-address">
                        <p className="summary-greyText summary-street">{sessionController?.user?.address?.street + ' ' + sessionController?.user?.address?.house_number}</p>
                        <p className="summary-greyText summary-city">{sessionController?.user?.address?.city}</p>
                    </div>
                    {sessionController?.userExists?.exists && (
                        <UserExists/>
                    )}
                </div>
                {!sessionController?.userExists?.exists && (
                    <ActionButtons nextStep={onNextStepHandler} prevStep={onPrevStepHandler}/>
                )}
            </ContentCol>
        </ViewContainer>
    );
};

export default withRouter(VerificationSummary);