import React from "react";

import {Col, Row} from "react-bootstrap";
import Hands from "assets/UserAssets/rowIcons/hands.svg";
import GreenCash from "assets/UserAssets/rowIcons/greenCash.svg";
import CoinCash from "assets/UserAssets/rowIcons/coinCash.svg";
import Clock from "assets/UserAssets/rowIcons/clock.svg";

const iconRow = () => (
        <Row className={'d-flex justify-content-center text-md-center icon-row'}>
            <Col lg="3" className="mobile-d-flex">
                <div className="footer-icon">
                    <img src={Hands} alt="Hands icon"/>
                </div>
                <p className="mt-3">
                    Každý den <b>schválíme 78% žádostí</b>
                </p>
            </Col>
            <Col lg="3"  className="mobile-d-flex">
                <div className="footer-icon">
                    <img src={GreenCash} alt="Hands icon"/>
                </div>
                <p className="mt-3">
                    <b>Úvěr 3.000,-</b> opakovaně <b>ZDARMA</b>
                </p>
            </Col>
            <Col lg="3"  className="mobile-d-flex">
                <div className="footer-icon">
                    <img src={CoinCash} alt="Hands icon"/>
                </div>
                <p className="mt-3">
                    <b>VistaRENT</b> - pronájem hotovosti
                </p>
            </Col>
            <Col lg="3"  className="mobile-d-flex">
                <div className="footer-icon">
                    <img src={Clock} alt="Hands icon"/>
                </div>
                <p className="mt-3">
                    <b>8.000,-</b> na 14 dnů opakovaně <b>ZDARMA</b>
                </p>
            </Col>
        </Row>
);

export default iconRow;