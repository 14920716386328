import React from 'react';
import Celebrate from "assets/UserAssets/celebrate.svg";
import {Col} from "react-bootstrap";
import clsx from "clsx";
import {BsPrefixPropsWithChildren} from "react-bootstrap/helpers";

declare type NumberAttr = number | '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10' | '11' | '12';
declare type ColSize = boolean | 'auto' | NumberAttr;
declare type ColSpec = ColSize

interface ImageColProps extends BsPrefixPropsWithChildren {
    image: string | undefined,
    alt?: string | undefined,
    class?: string | undefined,
    style?: Object,
    xs?: ColSpec | undefined,
    md?: ColSpec | undefined,
    lg?: ColSpec | undefined,
    xl?: ColSpec | undefined
}

const ImageCol = (props: ImageColProps) => {
    return (
        <Col xs={props.xs} md={props.md} lg={props.lg} xl={props.xl} className={clsx("d-flex justify-content-center DesktopOnly", props.class)}>
            <img src={props.image} style={props.style} alt={props.alt}/>

        </Col>
    );
};

export default ImageCol;