import React, {useState} from 'react';
import SwitchButton from "components/Cookies/components/SwitchButton";

interface Props {
    title: string,
    inputName: string,
    register: any,
    children: React.ReactNode | React.ReactNode[],
    disabled?: boolean
}

const CookieRow = (props: Props) => {
    return (
        <div className="CookieRow">
            <div className="d-flex justify-content-between">
                <h2>{props.title}</h2>
                <SwitchButton register={props.register} name={props.inputName} disabled={props.disabled}/>
            </div>
            <div className={"text-left w-75"}>{props.children}</div>
        </div>
    );
};

export default CookieRow;