import clsx from "clsx";
import React from 'react';
import 'components/UserComponents/Navigation/SideDrawer/DrawerToggler/DrawerToggler.scss';

interface Props {
    visible: boolean,
    clicked: () => void
}

const drawerToggle = (props: Props) => (
    <div className={clsx("DrawerToggler ", props.visible && "open")} onClick={props.clicked}>
        <div></div>
        <div></div>
        <div></div>
    </div>
);

export default drawerToggle;