import React, {useEffect, useState} from 'react';
import {Row} from "react-bootstrap";
import {useTypedSelector} from "helpers/reducers";
import {AppCurrentStep} from "types/enums/AppCurrentStep";
import {getNumberOfCurrentStep} from "helpers/utils";
import clsx from "clsx";

const MobileProgressNav = props => {
    const appController = useTypedSelector(state => state.appController);
    const [currentStep, setCurrentStep] = useState(1);
    const [isMobileNavShow, setIsMobileNavShow] = useState(true);

    useEffect(() => {
        setCurrentStep(getNumberOfCurrentStep(appController.current_step, appController));
        if (appController.current_step === AppCurrentStep.Finish) {
            setIsMobileNavShow(false);
        }
    }, [appController.current_step]);


    return (
        <>
            {isMobileNavShow && (
                <Row className="mobileProgress m-0 w-100">
                    <ul className="mobileProgress-list mobileOnly mb-0">
                        <li className={clsx("mobileProgress-listItem1", currentStep <= 4 ? "activeStatusMobile" : "finishedStatusMobile")}> </li>
                        <li className={clsx("mobileProgress-listItem2", currentStep > 4 && currentStep === 5 ? 'activeStatusMobile' : currentStep > 4 ? 'finishedStatusMobile' : '')}> </li>
                        <li className={clsx("mobileProgress-listItem3", currentStep > 5 && currentStep <= 7 ? 'activeStatusMobile' : currentStep > 5 ? 'finishedStatusMobile' : '')}> </li>
                        <li className={clsx("mobileProgress-listItem4", currentStep > 7 && currentStep === 8 ? 'activeStatusMobile' : currentStep > 8 ? 'finishedStatusMobile' : '')}> </li>
                        <li className={clsx("mobileProgress-listItem5", currentStep > 8 && currentStep === 9 ? 'activeStatusMobile' : currentStep > 8 ? 'finishedStatusMobile' : '')}> </li>
                    </ul>
                </Row>
            )}
        </>
    );
};

export default MobileProgressNav;