import React from 'react';
import {useTypedSelector} from "helpers/reducers";
import Button from "components/Button";
import {withRouter} from "react-router";


const ContactFormMessage = props => {
    const appController = useTypedSelector(state => state.appController);

    return (
        <>
            <div style={{height: "420px", marginBottom: "40px", maxWidth: '320px', position: 'relative'}}>
                {appController.emailResponse.success
                    ? <h3 className="contact-success" >Vaši zprávu jsme úspěšne odeslali!</h3>
                    : <h3 className="contact-error" >Vaši zprávu se nepodařilo odeslat, prosím zkuste to později!</h3>
                }
            </div>
            <div style={{marginBottom: "20px", maxWidth: '320px'}}>
                <Button class={"btn btn-purple"} onClick={props.onClick}>Zpět domů</Button>
            </div>
        </>
    );
};

export default withRouter(ContactFormMessage);