import React from 'react';
import {Col} from "react-bootstrap";
import clsx from "clsx";
import {BsPrefixPropsWithChildren} from "react-bootstrap/helpers";

declare type NumberAttr = number | '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10' | '11' | '12';
declare type ColSize = boolean | 'auto' | NumberAttr;
declare type ColSpec = ColSize

interface ContentColProps {
    children: any,
    class?: string | undefined,
    xs?: ColSpec | undefined,
    md?: ColSpec | undefined,
    lg?: ColSpec | undefined,
    xl?: ColSpec | undefined,
    style?: Object
}

const ContentCol = (props: ContentColProps) => {

    return (
        <Col xs={props.xs} md={props.md} lg={props.lg} xl={props.xl} className={clsx("checkPerson-textBox", props.class)} style={props.style}>
            {props.children}
        </Col>
    );
};

export default ContentCol;