import React from 'react';
import {CLIENT_URL} from "helpers/conifg";

const UserExists = props => {
    return (
        <div className="summary-loginComponent">
            <p className="summary-blueText">Ale my jsme se už viděli</p>
            <a href={CLIENT_URL}
               className="btn btn-purple summary-btn-purple">
                Přihlásit se k účtu
            </a>
        </div>
    );
};

export default UserExists;