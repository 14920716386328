import React from "react";
import 'components/UserComponents/Navigation/SideDrawer/SideDrawer.scss';
import Aux from "hoc/Auxiliary";
import NavigationItems from 'components/UserComponents/Navigation/NavigationItems/NavigationItems';
import Backdrop from 'shared/UI/Backdrop/Backdrop';


const SideDrawer = props => {
    let attachedClasses = ['SideDrawer', 'Close'];
    if (props.open) {
        attachedClasses = ['SideDrawer', 'Open']
    }

    return (
        <Aux>
            {/*<Backdrop show={props.open} clicked={props.closed} />*/}
            <div className={attachedClasses.join(' ')}>
                <nav>
                    <NavigationItems onClose={props.closed}/>
                </nav>
            </div>
        </Aux>
    );
};

export default SideDrawer;