import React, {useEffect} from 'react';
import ImageCol from "components/ImageCol";
import ContentCol from "components/ContentCol";
import ViewContainer from "components/ViewContainer";
import ThumbDown from "assets/UserAssets/thumbDown.svg";
import {vokativ} from "vokativ";
import {useTypedSelector} from "helpers/reducers";
import {capitalize} from "shared/utility";

const VerificationQuestionsFail = props => {
    const user = useTypedSelector(state => state.sessionController.user);
    const name = user?.first_name ? user.first_name : "";

    return (
        <ViewContainer>
            <ImageCol lg={3} image={ThumbDown}/>
            <ContentCol lg={6} class={'failedBox'}>
                <div className="failedMessage">
                    <p>
                        Bohužel, <b>{capitalize(vokativ(name))}</b>
                        <br /><br />
                        <b>velice si vážíme Vaší upřímnosti</b>, ale zdá se nám, že poskytnout Vám úvěr by nebylo dobré rozhodnutí.
                        <br /><br />
                        Jsme také rádi, <b>že jste nám nezamlčel důležité informace</b>, protože asi víte, že <b>je to trestný čin</b>.
                        <br /><br />
                        Nezbývá nám nic jiného, než se s Vámi rozloučit.
                        <b>Věříme, že za jiných okolností a v příznivější době společně obchod uzavřeme</b>.
                        <br /><br />
                        Mějte se fajn.
                    </p>
                </div>
            </ContentCol>
        </ViewContainer>
    );
};

export default VerificationQuestionsFail;