import React from 'react';
import clsx from "clsx";

interface Props {
    register: any,
    name: string,
    disabled?: boolean
}

const SwitchButton = (props: Props) => {
    return (
        <div className="custom-control custom-switch">
            <label className="switch">
                <input {...props.register(props.name)}  type="checkbox" disabled={props.disabled}/>
                    <span className={clsx("slider round", props.disabled && "opacity-50")}></span>
            </label>
            {/*<input ref={props.register} type="checkbox" className="custom-control-input" id="customSwitch1" onChange={(e) => props.setIsChecked(e.target.checked)} checked={props.isChecked}/>*/}
            {/*    <label className="custom-control-label" htmlFor="customSwitch1"></label>*/}
        </div>
    );
};

export default SwitchButton;