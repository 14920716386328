import React, {ReactNode} from 'react';
import clsx from "clsx";

interface Props {
    register: any,
    error?: any,
    type?: string,
    name: string,
    label: any,
    onChange?: (e) => void
    onBlur?: () => void
    children?: ReactNode | ReactNode[]
}

const InputElement = (props: Props) => {
    return (
        <div className="input">
            {props.onBlur ? (
                <>
                    <input
                        {...props.register(props.name)}
                        type={props.type}
                        onChange={props.onChange}
                        onBlur={props.onBlur}
                        className={clsx("inputElement", props.error?.message && 'error-border')}/>
                    {props.error && (<div
                        className={clsx("error", props.error ? 'error-show' : "pointer-events-none")}>{props.error?.message}</div>)}
                    {props.children}
                </>
            ) : (
                <>
                    <input
                        {...props.register(props.name)}
                        type={props.type}
                        onChange={props.onChange}
                        className={clsx("inputElement", props.error?.message && 'error-border')}/>
                    {props.error && (<div
                        className={clsx("error", props.error ? 'error-show' : "pointer-events-none")}>{props.error?.message}</div>)}
                    {props.children}
                </>
            )}
        </div>
    );
};

export default InputElement;