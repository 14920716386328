import React from 'react';
import Button from "components/Button";

interface Props {
    onSubmit: () => void,
    register: any,
}

const ContactForm = (props: Props) => {


    return (
        <form onSubmit={props.onSubmit}>
            <h3>Napište nám</h3>
            <textarea {...props.register("message")} name="message" id="" cols={30} rows={10} placeholder="Co chcete, abychom věděli...">
                                </textarea>
            <div className="email">
                <input {...props.register("email")} type="email" name={"email"} placeholder="Váš e-mail"/>
            </div>
            <div className="name">
                <input {...props.register("name")} type="text" name={"name"} placeholder="Vaše jméno"/>
            </div>
            <Button
                type={"submit"}
                class="btn btn-purple mb-1">
                ODESLAT
            </Button>
        </form>
    );
};

export default ContactForm;