import clsx from "clsx";
import React from "react";
import { NavLink } from 'react-router-dom';

import './NavigationItem.scss';

const NavigationItem = props => (
  <li className="nav-link">
      <NavLink
          to={props.link}
          id={props.id}
          className={clsx('d-block w-100', props.class)}
          style={props.style}
          exact={props.exact}
          onClick={props.click}>{props.children}</NavLink>
  </li>
);

export default NavigationItem;