import React from 'react';
import {verificationType} from "types/enums/VerificationType";
import {getVerificationResult} from "helpers/utils";
import clsx from "clsx";

interface Props {
    verificationType: verificationType,
    result: any
}

const VerificationResultItem = (props: Props) => {
    const verificationItem = getVerificationResult(props.result, props.verificationType);

    return (
        <div className="d-flex justify-content-center justify-content-lg-start lustration-icon-wrap mb-3 mb-lg-0">
            <img src={verificationItem?.image} height={24} width={24} className="lustration-icon mr-2" alt=""/>
            <p className={clsx("mb-0 w-lg-100", verificationItem?.classes)}>
                {verificationItem?.text}
            </p>
        </div>
    );
};

export default VerificationResultItem;