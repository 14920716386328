
class LinkTo {
    static home = () => "/";
    static calculation = () => "/zadost/vyber";
    static verification = () => "/zadost/udaje";
    static verificationSummary = () => "/zadost/rejstrik";
    static calculationSummary = () => "/zadost/shrnuti";
    static verificationQuestions = () => "/zadost/otazky";
    static verificationFailed = () => "/zadost/zamitnuto";
    static verificationFailedCauseAge = () => "/zamitnuto";
    static purpose = () => "/ucel-poskytnuti/ucel";
    static purposeRepeat = () => "/ucel-poskytnuti/opakovany-uver";
    static spendingReport = () => "/ucel-poskytnuti/finance";
    static address = () => "/doplnujici-udaje/adresa";
    static registration = () => "/doplnujici-udaje/kontakt";
    static sendRequest = () => "/odeslani-zadosti/souhlasy";
    static finish = () => "/konec"

    static contact = () => "/kontakt"
}

export default LinkTo