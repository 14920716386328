import React, {useEffect, useState} from 'react';
import {useTypedSelector} from "helpers/reducers";
import Button from "components/Button";
import {useDispatch} from "useDispatch";
import {closeModal} from "store/action";
import AppControllerAction from "store/appControler/AppControllerAction";
import {RouteComponentProps, withRouter} from "react-router";
import LinkTo from "helpers/LinkTo";
import {AppCurrentStep} from "types/enums/AppCurrentStep";
import {AppStatus} from "types/enums/AppStatus";
import {AppProgressStatus} from "types/enums/AppProgressStatus";
import VerificationResultItem from "pages/finish/components/VerificationResultItem";
import {verificationType} from "types/enums/VerificationType";
import Loader from "components/Loader";
import SessionControllerAction from "store/sessionController/SessionControllerAction";
import {getNextStep} from "helpers/utils";
import LoanAction from "store/loan/LoanAction";
import Contracter from "assets/UserAssets/contract.svg";
import clsx from "clsx";
import ImageCol from "components/ImageCol";
import {FILE_URL} from "helpers/conifg";

interface Params {}

interface Props extends RouteComponentProps<Params> {
    counter?: any,
}

const FinishVerificationModal = (props: Props) => {
    const dispatch = useDispatch();
    const session = useTypedSelector(state => state.sessionController);
    const appController = useTypedSelector(state => state.appController);
    const questions = session.verificationQuestions;
    const loanController = useTypedSelector(state => state.loanController);
    const lustration = useTypedSelector(state => state.sessionController.lustrations);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [personalDataLoanChecked, setPersonalDataLoanChecked] = useState(false);


    const next = () => {
        const nextStep = getNextStep(appController.current_step);
        dispatch(AppControllerAction.setCurrentStep(nextStep.newStep))
        props.history.push(nextStep.page);
    }

    const onChangeHandler = () => {
        dispatch(LoanAction.setAgreement({name: "personalData", value: true}));
        setPersonalDataLoanChecked(true);
    };

    useEffect(() => {
        if (loanController.sendRequest.personalData) {
            dispatch(SessionControllerAction.fetchLustrations({
                nin: session.user.birth_number,
                ico: session.user.company_number,
                loan_amount: loanController.loan.loan_selection.selected_price,
                answers: [
                    session.verificationQuestions.has_insolvency,
                    session.verificationQuestions.has_execution,
                    session.verificationQuestions.has_office_address
                ]
            }));
        }
    }, [personalDataLoanChecked]);

    useEffect(() => {
        if (Object.keys(lustration).length !== 0) {
            setIsButtonDisabled(false)
        }

    }, [lustration]);

    const onContinueHandler = () => {
        if (lustration.address === false
            || lustration.insolvency === false
            || lustration.blacklist === false
            || lustration.internal === false) {
            dispatch(closeModal());
            dispatch(AppControllerAction.setCurrentStep(AppCurrentStep.VerificationQuestionsFail));
            dispatch(AppControllerAction.setAppStatus(AppStatus.Error));
            dispatch(AppControllerAction.setAppProgressStatus(AppProgressStatus.Error));
            props.history.push(LinkTo.verificationFailed());
        } else {
            dispatch(closeModal());
            next();
        }
    }

    return (
        <div className="FinishVerificationModal">
            {!loanController.sendRequest.personalData && <h2 className={"checkBox-h2 page__section-title"}>Souhlas se zpracováním osobních údajů</h2>}
    
            {!loanController.sendRequest.personalData
                ? (
                    <form>
                        <ul className="calcSummary-list Question-list Contract-list personalDataAgreement">
                            <li className={clsx("calcSummary-listItem Question-listItem", personalDataLoanChecked && "Question-listItem__active")}>
                                <div className="d-flex">
                                    <h2 className={"checkBox-h2"}>Souhlasím se zpracováním mých <br/><a href={`${FILE_URL}/static/web/pdf/ochrana_osobnich_udaju.pdf`} target="_blank" className="linkBlue" rel="noopener noreferrer">osobních údajů</a> pro účel poskytnutí úvěru.</h2>
                                </div>
                            </li>
                        </ul>
                        <button className={"btn btn-purple Modal-btn mx-auto"} onClick={onChangeHandler}>Souhlasím</button>
                        <div className="decline-btn" onClick={() => dispatch(closeModal())}>Nesouhlasim</div>
                    </form>
                )
                :(
                    <>
                        <h3 className="Modal-heading mb-5">
                           <b>Důvěřuj, ale prověřuj...</b> se říká. Provedeme rychlou kontrolu základních údajů a zjistíme, jestli společně můžeme uzavřít obchod.
                        </h3>
                    <div className="lustrations d-flex flex-column flex-wrap text-left">
                        <div className="lustration d-flex flex-column flex-lg-row flex-row justify-content-between">
                        <p>Překontrolujeme naši vnitřní databázi:</p>
                    {lustration?.internal !== undefined
                        ? <VerificationResultItem verificationType={verificationType.DATABASE} result={lustration.internal}/>
                        : <div className="Finish-loader"><Loader /></div>
                    }

                        </div>
                        <div className="lustration d-flex flex-column flex-lg-row flex-row justify-content-between">
                        <p>Taky se podíváme, jestli u nás už nemáte nějaký škraloup:</p>
                    {lustration?.blacklist !== undefined
                        ? <VerificationResultItem verificationType={verificationType.BLACK_LIST} result={lustration.blacklist}/>
                        : <div className="Finish-loader"><Loader /></div>
                    }
                        </div>
                        <div className="lustration d-flex flex-column flex-lg-row flex-row justify-content-between">
                        <p>A co insolvence? Nejste v úpadku?:</p>
                    {lustration?.insolvency !== undefined
                        ? <VerificationResultItem verificationType={verificationType.INSOLVENCY} result={lustration.insolvency}/>
                        : <div className="Finish-loader"><Loader /></div>
                    }
                        </div>
                    <div className="lustration d-flex flex-column flex-lg-row flex-row justify-content-between">
                        <p>Odpovědi na otázky:</p>
                        
                        {lustration?.insolvency !== undefined
                            ? <VerificationResultItem verificationType={verificationType.ADDRESS} result={!questions.has_office_address && !questions.has_insolvency && !questions.has_execution}/>
                            : <div className="Finish-loader"><Loader /></div>
                        }
                    </div>
                        </div>
                        <button className={"btn btn-purple Modal-btn mt-5 mx-auto"} onClick={onContinueHandler} disabled={isButtonDisabled}>Pokračuj</button>
                    </>

                )}
            {!loanController.sendRequest.personalData && <img src={Contracter} className={'verifyImage'} alt={""}/>}
        </div>
    );
};

export default withRouter(FinishVerificationModal);